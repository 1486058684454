import React, { Fragment, useState, useEffect, useMemo } from 'react'
import {
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem
} from 'reactstrap'
import flagImg from '../../assets/images/flag.png'
import chinaFlag from '../../assets/images/china.png'
import jap from '../../assets/images/flag2.png'
import kor from '../../assets/images/flag3.png'
import hin from '../../assets/images/flag4.png'
import fil from '../../assets/images/flag6.png'
import thai from '../../assets/images/flag5.png'
import indo from '../../assets/images/flag7.png'
import viet from '../../assets/images/flag8.png'

import droparrImg from '../../assets/images/drop-arr.png'
import SocialLink from '../SocialLink/SocialLink'
import { useTranslation } from 'react-i18next'

const Footer = () => {
	const { t, i18n } = useTranslation()

	const [isOpen, setIsOpen] = useState(false)

	const toggle = () => {
		setIsOpen(!isOpen)
	}

	const lang = [
		{
			name: 'English',
			image: flagImg,
			code: 'English'
		},
		{
			name: 'Japanese',
			image: jap,
			code: 'Japanese'
		},
		{
			name: 'Korean',
			image: kor,
			code: 'Korean'
		},
		{
			name: 'Hindi',
			image: hin,
			code: 'Hindi'
		},
		{
			name: 'Filipino',
			image: fil,
			code: 'Filipino'
		},
		{
			name: 'Thai',
			image: thai,
			code: 'Thai'
		},
		{
			name: 'Indonesian',
			image: indo,
			code: 'Indonesian'
		},
		{
			name: 'Vietnamese',
			image: viet,
			code: 'Vietnamese'
		},
		{
			name: 'Chinese',
			image: chinaFlag,
			code: 'Chinese'
		}
	]
	const [language, setLanguage] = useState('English')

	const selectedLanguageImage = useMemo(
		() => lang.find(({ name }) => name === language),
		[language]
	)

	useEffect(() => {
		const langCheck = localStorage.getItem('lang')
		if (langCheck === null || undefined) {
			localStorage.setItem('lang', language)
			i18n.changeLanguage(language)
		} else {
			setLanguage(langCheck)
			i18n.changeLanguage(langCheck)
		}
	}, [language])

	const handleItemClick = item => {
		setLanguage(item)
		localStorage.setItem('lang', item)
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}
	return (
		<Fragment>
			<footer>
				<div className='ftr-social'>
					<SocialLink />
				</div>
				<p className='copy-txt'>
					© 2023 Global Digital Analytics Incorporated Limited{' '}
					{t('All rights reserved')}.
				</p>
				<div className='langdd'>
					<Dropdown isOpen={isOpen} toggle={toggle}>
						<DropdownToggle className='d-flex justify-content-between align-items-center'>
							<div>
								<img
									src={selectedLanguageImage?.image}
									alt=''
									className='img-fluid langImg'
								/>{' '}
								<span className='mr-2'>
									{language ? language : 'Select language'}
								</span>{' '}
							</div>
							<img src={droparrImg} alt='' className='img-fluid' />
						</DropdownToggle>
						<DropdownMenu>
							{lang.map(item => (
								<DropdownItem
									key={item.code}
									onClick={() => handleItemClick(item.name)}
								>
									<img src={item.image} alt='' className='img-fluid langImg' />{' '}
									<span className='mr-2'>{item.name}</span>
								</DropdownItem>
							))}
						</DropdownMenu>
					</Dropdown>
				</div>
			</footer>
		</Fragment>
	)
}

export default Footer
