import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import translationEN from './english.json'
import translationCH from './chinese.json'
import translationFI from './filipino.json'
import translationHI from './hindi.json'
import translationIN from './indonesian.json'
import translationJP from './japanese.json'
import translationKR from './korean.json'
import translationTH from './thai.json'
import translationVI from './vietnamese.json'

const resources = {
	English: {
		translation: translationEN
	},
	Chinese: {
		translation: translationCH
	},
	Filipino: {
		translation: translationFI
	},
	Hindi: {
		translation: translationHI
	},
	Indonesian: {
		translation: translationIN
	},
	Japanese: {
		translation: translationJP
	},
	Korean: {
		translation: translationKR
	},
	Thai: {
		translation: translationTH
	},
	Vietnamese: {
		translation: translationVI
	}
}

i18n
	.use(initReactI18next)
	.use(LanguageDetector)
	.init({
		resources,
		lng: localStorage.getItem('lang'),
		keySeparator: false,
		interpolation: {
			escapeValue: false
		}
	})
