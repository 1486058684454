import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { axiosData } from '../../../utils/axios-utils'
import { useTranslation } from 'react-i18next'
import DataService from '../../../utils/DataService'
const { postRequest } = DataService()

const verifyEmail = async loginData => {
	return postRequest('/user/resend_mail', {
		email: loginData.email
	})
}

export const useVerifyEmail = () => {
	const { t } = useTranslation()

	return useMutation(verifyEmail, {
		onMutate: () => {
		},
		onError: error => {
		},
		onSuccess: loginSuccessData => {
			
			if (loginSuccessData?.status === 400) {
					toast.error(t(loginSuccessData?.message))
			}

			if (loginSuccessData?.status === 200) {
				toast.success(t('Verification link has been resend your mail!'))
			}

			if (loginSuccessData?.status === false) {
					toast.error(t(loginSuccessData?.message))
			}
		}
	})
}
