import { useQuery } from 'react-query'
import { axiosData } from '../../../utils/axios-utils'
import DataService from '../../../utils/DataService'
const { getRequest } = DataService()


const cmsList = async () => {
	return getRequest('/common/cms-list')
}

export const useCmsList = () => {
	return useQuery({
		queryKey: ['cms'],
		queryFn: () => cmsList()
	})
}
