import React, { Fragment, useEffect } from 'react'
import Sidemenu from '../../common/Sidemenu/Sidemenu'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Loader from '../../utils/loader'
import { useSupportPage } from './api'
import { useTranslation } from 'react-i18next'

const EMAILID_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/
const USERNAME_REGEX = /^[a-zA-Z0-9]*$/
const NAME_REGEX = /^[a-zA-Z0-9\s]{3,20}$/

const Contact = () => {
	const formSchema = Yup.object().shape({
		exchangeId: Yup.string()
			.required('Enter Exchange Id')
			.matches(USERNAME_REGEX, 'Enter only Alphanumeric characters'),
		email: Yup.string()
			.required('Enter Email Id')
			.matches(EMAILID_REGEX, 'Enter a valid email'),
		name: Yup.string()
			.required('Enter Name')
			.min(3, 'User Name should be atleast 3 characters')
			.max(20, 'User Name should be within 20 characters')
			.matches(NAME_REGEX, 'Enter only Alphanumeric characters'),
		subject: Yup.string()
			.required('Enter Subject')
			.min(4, 'Subject should be atleast 4 characters')
			.max(250, 'Subject should be within 250 characters'),
		message: Yup.string()
			.required('Enter Message')
			.min(4, 'Message should be atleast 4 characters')
			.max(500, 'Message should be within 500 characters')
	})
	const { t } = useTranslation()

	const formOptions = { resolver: yupResolver(formSchema) }

	const supportMutation = useSupportPage()

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm(formOptions)

	const onSubmit = data => {
		localStorage.setItem('email', data.email)
		supportMutation.mutate(data)
	}

	useEffect(() => {
		if (supportMutation.isSuccess) {
			reset()
		}
	}, [supportMutation.isSuccess])

	return (
		<Fragment>
			<div className='BgltNone'>
				<div className='page-tot'>
					<div className='LogMainSection'>
						<div className='row align-items-center mx-0'>
							<Sidemenu />
							<div className='col-lg-6'>
								<div className='LognSetMain py-3'>
									<div className='LognRtMain'>
										<div className='LognHdd text-center mb-3 mb-sm-5'>
											<h4>{t('Contact Us')}</h4>
											<p>{t('Write a Message to Neo Swap')}</p>
										</div>
										<form className='LogForm' onSubmit={handleSubmit(onSubmit)}>
											<div className='mb-3'>
												<label
													htmlFor='e1'
													className='form-label sr-only'
												></label>
												<input
													type='text'
													className='form-control'
													id='e1'
													placeholder={t('Exchange Id')}
													{...register('exchangeId')}
													autoFocus
												/>
											</div>
											{errors.exchangeId && (
												<p className='errorMsg'>
													{t(errors.exchangeId.message)}
												</p>
											)}
											<div className='mb-3'>
												<label
													htmlFor='e1'
													className='form-label sr-only'
												></label>
												<input
													type='text'
													className='form-control'
													id='e1'
													placeholder={t('Name')}
													{...register('name')}
												/>
											</div>
											{errors.name && (
												<p className='errorMsg'>{t(errors.name.message)}</p>
											)}
											<div className='mb-3'>
												<label
													htmlFor='e1'
													className='form-label sr-only'
												></label>
												<input
													type='email'
													className='form-control'
													id='e1'
													placeholder={t('Email Id')}
													{...register('email')}
												/>
											</div>
											{errors.email && (
												<p className='errorMsg'>{t(errors.email.message)}</p>
											)}
											<div className='mb-3'>
												<label
													htmlFor='e1'
													className='form-label sr-only'
												></label>
												<input
													type='text'
													className='form-control'
													id='e1'
													placeholder={t('Subject')}
													{...register('subject')}
												/>
											</div>
											{errors.subject && (
												<p className='errorMsg'>{t(errors.subject.message)}</p>
											)}
											<div className='mb-3'>
												<label
													htmlFor='e1'
													className='form-label sr-only'
												></label>
												<textarea
													className='form-control'
													id='exampleFormControlTextarea1'
													rows='4'
													placeholder={t('Message')}
													{...register('message')}
												></textarea>
											</div>
											{errors.message && (
												<p className='errorMsg'>{t(errors.message.message)}</p>
											)}
											<div className='text-center mt-4 pt-2'>
												<button
													className='btn btn-primary Btn215-43'
													type='submit'
												>
													{supportMutation.isLoading ? (
														<Loader />
													) : (
														<>{t('Send')}</>
													)}
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default Contact
