import React, { Fragment, useEffect, useState } from 'react'
import {
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem
} from 'reactstrap'
import Header from '../../common/Header/Header'
import Footer from '../../common/Footer/Footer'
import { NumericFormat } from 'react-number-format'
import exchangeImg from '../../assets/images/exchange-icon.svg'
import { useCurrencyList, useCreateAddress, usePairPrice } from './api'
import Loader from '../../utils/loader'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setFromWalletToken, setInitialExchange } from '../Walletaddress/slice'
import { useTranslation } from 'react-i18next'
import TokenLoader from '../../utils/TokenLoader'
import { useDebounce } from '../../utils/toFixed'

const Home = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const dispatch = useDispatch()

	const currencyListMutation = useCurrencyList()
	const createAddressMutation = useCreateAddress()
	const ToPairPriceMutation = usePairPrice()

	const [currencyList, setCurrencyList] = useState([])

	const [fromToken, setFromToken] = useState()
	const [toToken, setToToken] = useState()

	const [error, setError] = useState('')

	const [fromAmount, setFromAmount] = useState(0.1)
	const [toAmount, setToAmount] = useState(0)

	

	const [isDisabled, setIsDisabled] = useState(false)

	useEffect(() => {
		currencyListMutation.mutate()
	}, [])

	const listSocialLink = () => {
		if (currencyListMutation.isSuccess) {
			setCurrencyList(
				currencyListMutation && currencyListMutation?.data?.data
			)
			setFromToken(
				currencyListMutation && currencyListMutation?.data?.data?.[0]
			)
			setToToken(
				currencyListMutation && currencyListMutation?.data?.data?.at(-1)
			)
		} else {
			setCurrencyList([])
		}
	}

	useEffect(() => {
		listSocialLink()
	}, [currencyListMutation.isSuccess])

	const selectFromToken = data => {
		let temp = data
		if (temp._id === toToken?._id) {
			setFromToken(toToken)
			setToToken(fromToken)
		} else {
			setFromToken(data)
		}
		amountCalculate()
	}

	const selectToToken = data => {
		let temp = data
		if (temp._id === fromToken?._id) {
			setFromToken(toToken)
			setToToken(fromToken)
		} else {
			setToToken(data)
		}
		amountCalculate()
	}

	const swapChange = e => {
		e.preventDefault()
		setFromToken(toToken)
		setToToken(fromToken)
	}

	

	const onUserInput = values => {
		if (values === '') {
			setFromAmount(null)
			setToAmount(0)
			return
		}
		const regex = /^\d{1,15}(\.\d{1,15})?$/
		if (regex.test(+values)) {
			if (+values < ToPairPriceMutation?.data?.data?.fromMin) {
				setIsDisabled(true)
				setError(`Min amount ${ToPairPriceMutation?.data?.data?.fromMin}`)
				return
			} else if (+values > ToPairPriceMutation?.data?.data?.fromMax) {
				setIsDisabled(true)
				setError(`Max amount ${ToPairPriceMutation?.data?.data?.fromMax}`)
				return
			} else {
				setFromAmount(+values)
				setError('')
				setIsDisabled(false)
			}
		} else {
			setError('Pair is not supported')
		}
	}

	const debouncedSearchTerm = useDebounce(fromAmount, 100)

	const fromAmountCheck = () => {
		if (+fromAmount < +ToPairPriceMutation?.data?.data?.fromMin) {
			setIsDisabled(true)
			setError(`Min amount ${ToPairPriceMutation?.data?.data?.fromMin}`)
			return
		} else if (+fromAmount > +ToPairPriceMutation?.data?.data?.fromMax) {
			setIsDisabled(true)
			setError(`Max amount ${ToPairPriceMutation?.data?.data?.fromMax}`)
			return
		} else {
			setFromAmount(+fromAmount)
			setError('')
			setIsDisabled(false)
		}
	}

	const amountCalculate = async () => {
		const data = {
			fromCurrency: `${fromToken?.coinName}(${fromToken?.coinType})`,
			toCurrency: `${toToken?.coinName}(${toToken?.coinType})`,
			fromCurrencyPrice: +fromAmount
		}
		if (fromToken !== undefined && toToken !== undefined) {
			await ToPairPriceMutation.mutate(data)
		}
	}

	useEffect(() => {
		if (ToPairPriceMutation.isSuccess) {
		}
	}, [ToPairPriceMutation.isSuccess])

	useEffect(() => {
		if (ToPairPriceMutation.isSuccess) {
			setToAmount(ToPairPriceMutation?.data?.data?.toCurrencyPrice)
			fromAmountCheck()
		}
	}, [ToPairPriceMutation.isSuccess])

	useEffect(() => {
		amountCalculate()
	}, [debouncedSearchTerm, fromToken, toToken])

	const exchangeBtn = async () => {
		const data = {
			fromCurrency: fromToken.currency,
			fromAmount: fromAmount,
			toCurrency: toToken.currency,
			toNetwork: toToken.Network,
			toAmount: +Number(toAmount).toFixed(6)
		}

		dispatch(setFromWalletToken({ fromToken, toToken, fromAmount, toAmount }))
		dispatch(setInitialExchange(data))
		navigate(
			`/walletaddress/from=${data.fromCurrency}&to=${data.toCurrency}&amount=${data.fromAmount}&estimate=${data.toAmount}`
		)
	}

	return (
		<Fragment>
			<Header />
			<div className='pagemain-content'>
				<section className='bridge-sec main_jiu'>
					<div className='container'>
						<div className='bridge-head text-center mb-4'>
							<h5>
								{t('Instant')} {t('Crypto Exchange')}
							</h5>
							<p>{t('Easy to Use & Free from Sign-up')}</p>
						</div>
						<div className='bridge-maincnt bridge-maincnt-Hme'>
							<div className='bridge-body'>
								<form action='' className='cus-form'>
									<div className='form-group'>
										<label htmlFor=''>{t('You Send')}</label>
										<div className='row justify-content-center'>
											<div className='col-sm-7 pr-sm-0'>
												<div className='cusform-input'>
													<NumericFormat
														onValueChange={({ value }) => onUserInput(value)}
														className='numeric-input'
														allowNegative={false}
														value={fromAmount}
														onBlur={() => {
															if (fromAmount === '') {
																setError('Amount is required')
															}
														}}
													/>
													{error && <div className='text-danger'>{error}</div>}
												</div>
											</div>
											<div className='col-sm-5 ColAut'>
												<div className='cusform-input'>
													<UncontrolledDropdown className='CustDrpDwn'>
														<DropdownToggle>
															<div className='inputcoin-btn'>
																<div className='coinbtn-main'>
																	<div className='d-flex justify-content-between align-items-center'>
																		{currencyListMutation.isLoading ? (
																			<TokenLoader />
																		) : (
																			<>
																				<div>
																					<img
																						src={
																							fromToken && fromToken.coinImage
																						}
																						alt=''
																						className=''
																						style={{
																							width: '16px',
																							height: '16px'
																						}}
																					/>
																				</div>
																				<div className='mx-2 d-flex align-items-center'>
																					<h5 className='mr-2'>
																						{fromToken && fromToken.coinName}
																					</h5>
																					<span className='badge badge-pill badge-info'>
																						{fromToken && fromToken.coinType}
																					</span>
																				</div>
																				<div>
																					<svg
																						width='9'
																						height='6'
																						viewBox='0 0 9 6'
																						fill='none'
																						xmlns='http://www.w3.org/2000/svg'
																					>
																						<path
																							d='M1 1L4.5 5L8 1'
																							stroke='white'
																						/>
																					</svg>
																				</div>
																			</>
																		)}
																	</div>
																</div>
															</div>
														</DropdownToggle>
														<DropdownMenu>
															{currencyList?.map(data => {
																return (
																	<DropdownItem
																		key={data._id}
																		onClick={() => selectFromToken(data)}
																		defaultChecked={currencyList[0]}
																	>
																		<img
																			src={data.coinImage}
																			alt=''
																			className='img-fluid DrpDwnImg mr-2'
																		/>
																		{data.coinName}{' '}
																		<span className='badge badge-pill badge-info'>
																			{data.coinType}
																		</span>
																	</DropdownItem>
																)
															})}
														</DropdownMenu>
													</UncontrolledDropdown>
												</div>
											</div>
										</div>
										<div className='ExcngeBtn'>
											<button
												className='btn btn-link'
												onClick={e => swapChange(e)}
											>
												<img src={exchangeImg} alt='#' />
											</button>
										</div>
									</div>

									<div className='form-group'>
										<label htmlFor=''>{t('You Get')}</label>
										<div className='row justify-content-center'>
											<div className='col-sm-7 pr-sm-0'>
												<div className='cusform-input'>
													<div className='valChange'>
														{error === 'Pair is not supported' ? (
															'-'
														) : ToPairPriceMutation.isLoading ? (
															<div className='amountloader'></div>
														) : isNaN(toAmount) ? (
															'0.1'
														) : (
															parseFloat(toAmount?.toFixed(10))
														)}
													</div>
												</div>
											</div>
											<div className='col-sm-5 ColAut'>
												<div className='cusform-input'>
													<UncontrolledDropdown className='CustDrpDwn'>
														<DropdownToggle>
															<div className='inputcoin-btn'>
																<div className='coinbtn-main'>
																	<div className='d-flex justify-content-between align-items-center'>
																		{currencyListMutation.isLoading ? (
																			<TokenLoader />
																		) : (
																			<>
																				<div>
																					<img
																						src={toToken && toToken.coinImage}
																						alt=''
																						className=''
																						style={{
																							width: '16px',
																							height: '16px'
																						}}
																					/>
																				</div>
																				<div className='mx-2 d-flex align-items-center'>
																					<h5 className='mr-2'>
																						{toToken && toToken.coinName}
																					</h5>
																					<span className='badge badge-pill badge-info'>
																						{toToken && toToken.coinType}
																					</span>
																				</div>
																				<div>
																					<svg
																						width='9'
																						height='6'
																						viewBox='0 0 9 6'
																						fill='none'
																						xmlns='http://www.w3.org/2000/svg'
																					>
																						<path
																							d='M1 1L4.5 5L8 1'
																							stroke='white'
																						/>
																					</svg>
																				</div>
																			</>
																		)}
																	</div>
																</div>
															</div>
														</DropdownToggle>
														<DropdownMenu>
															{currencyList?.map(data => {
																return (
																	<DropdownItem
																		key={data._id}
																		onClick={() => selectToToken(data)}
																	>
																		<img
																			src={data.coinImage}
																			alt=''
																			className='img-fluid DrpDwnImg mr-2'
																		/>
																		{data.coinName}{' '}
																		<span className='badge badge-pill badge-info'>
																			{data.coinType}
																		</span>
																	</DropdownItem>
																)
															})}
														</DropdownMenu>
													</UncontrolledDropdown>
												</div>
											</div>
										</div>
									</div>
									<div className='form-cntwalt'>
										<button
											className={
												isDisabled
													? 'btn btn-primary disable'
													: 'btn btn-primary'
											}
											type='button'
											onClick={() => exchangeBtn()}
											disabled={isDisabled}
										>
											{createAddressMutation.isLoading ? (
												<Loader />
											) : (
												<>{t('Exchange')}</>
											)}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
			</div>
			<Footer />
		</Fragment>
	)
}

export default Home
