import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export function toFixed(x) {
	if (Math.abs(x) < 1.0) {
		var e = parseInt(x.toString().split('e-')[1])
		if (e) {
			x *= Math.pow(10, e - 1)
			x = '0.' + new Array(e).join('0') + x.toString().substring(2)
		}
	} else {
		var e = parseInt(x.toString().split('+')[1])
		if (e > 20) {
			e -= 20
			x /= Math.pow(10, e)
			x += new Array(e + 1).join('0')
		}
	}
	return x
}

export const copyToClipboard = (text, cb = () => {}) => {
	if (navigator.clipboard && navigator.permissions) {
		navigator.clipboard.writeText(text).then(cb)
	} else if (document.queryCommandSupported('copy')) {
		const ele = document.createElement('textarea')
		ele.value = text
		document.body.appendChild(ele)
		ele.select()
		document.execCommand('copy')
		document.body.removeChild(ele)
		cb?.()
	}
}

export const useDebounce = (value, delay) => {
	const [debouncedValue, setDebouncedValue] = useState(value)

	useEffect(() => {
		const timer = setTimeout(() => {
			setDebouncedValue(value)
		}, delay)

		return () => {
			clearTimeout(timer)
		}
	}, [value, delay])

	return debouncedValue
}

export const formatAddress = (address, padding = 19) => {
	if (address && address.length > 0) {
		return `${address.substr(0, padding)}…${address.substr(-19)}`
	} else {
		return address
	}
}

export const tableFormatAddress = (address, padding = 6) => {
	if (address && address.length > 0) {
		return `${address.substr(0, padding)}…${address.substr(-6)}`
	} else {
		return address
	}
}

export const formatValue = (value, fromCom, style) => {
	if (value) {
		let value_length = 9
		let str_value = String(value)
		let str_value_length = str_value.length
		value = +value
		if (str_value.includes('.')) {
			if (str_value.slice(0, 8) != '0.000000') {
				let split_str_value = str_value.split('.')
				if (split_str_value[0].length > 2) {
					return value.toPrecision(8)
				} else if (split_str_value[1].length > 6) {
					return value.toFixed(6)
				} else {
					return value.toFixed(6)
				}
			} else {
				let first_value_index = [...str_value].findIndex(
					(val, ind) => ind > 7 && 0 < val 
				)
				let after_zeros = str_value.slice(
					first_value_index,
					first_value_index + 3
				)
				return (
					<div className={style ? 'tool mr-5' : 'tool'} data-tip={str_value}>
						0.0...{after_zeros}
					</div>
				)
			}
		} else {
			if (str_value_length >= value_length) {
				return value
			} else {
				return value.toPrecision(8)
			}
		}
	} else {
		return fromCom ? 0 : '-'
	}
}

export const explorerRedirect = (info, route, type) => {
		if (type === 'Polygon') {
			return `https://mumbai.polygonscan.com/${route}/${info}`
		} else if (type === 'ETH') {
			return `https://sepolia.etherscan.io//${route}/${info}`
		} else if (type === 'TRX') {
			return `https://nile.tronscan.org/?_ga=2.70310672.14992521.1683109682-1631203932.1681821243#/${route}/${info}`
		}
}

export const useReactQuery = () => {
	const { search } = useLocation()

	return React.useMemo(() => new URLSearchParams(search), [search])
}

export const formatDateToDefaultLocale = date => {
	const options = {
		year: 'numeric',
		month: 'numeric',
		day: 'numeric',
		hour: 'numeric',
		minute: 'numeric',
		hour12: false
	}
	const dateObj = new Date(date)
	const formattedDate = new Intl.DateTimeFormat('default', options).format(
		dateObj
	)

	return formattedDate.split(',').join(' ,')
}
