import React, { Fragment, useState, useEffect, useCallback } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import Header from '../../common/Header/Header'
import Footer from '../../common/Footer/Footer'
import classnames from 'classnames'
import linkIcon from '../../assets/images/linkIcon.png'
import copyIcon from '../../assets/images/copyIcon.png'
import pendingDeposit from '../../assets/images/pendingDeposit.png'
import tickIcon from '../../assets/images/tickIcon.png'
import confirming from '../../assets/images/confirming.png'
import exchangeIcon from '../../assets/images/exchangeIcon.png'
import sending from '../../assets/images/sending.png'
import Success from '../../assets/images/success.png'
import Finished from '../../assets/images/finished.gif'
import qustImg from '../../assets/images/qust.png'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { useFindExchange } from './api'
import { setFindExchange } from '../Walletaddress/slice'
import { io } from 'socket.io-client'
import {
	formatAddress,
	copyToClipboard,
	explorerRedirect
} from '../../utils/toFixed'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel
} from 'react-accessible-accordion'

import 'react-accessible-accordion/dist/fancy-example.css'
import loading from '../../assets/images/Spinner.gif'

const Deposit = () => {
	const { t } = useTranslation()

	const dispatch = useDispatch()
	const { exchangeId } = useParams()
	var url = ''

	url = 'https://apinesjs.neo-swap.io'


	const socketEndpoint = io(url)

	const findExchangeMutation = useFindExchange()

	const [socket, setSocket] = useState()
	const [findTab, setFindTab] = useState()

	const [activeTab, setActiveTab] = useState('1')
	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab)
	}

	const [done, setDone] = useState(false)
	const [doneOne, setDoneOne] = useState(false)
	const [doneTwo, setDoneTwo] = useState(false)
	const [doneThree, setDoneThree] = useState(false)
	const [doneFour, setDoneFour] = useState(false)
	const [doneFive, setDoneFive] = useState(false)
	const [doneSix, setDoneSix] = useState(false)
	const [doneSeven, setDoneSeven] = useState(false)
	const [doneEight, setDoneEight] = useState(false)
	const [doneNine, setDoneNine] = useState(false)
	const [doneTen, setDoneTen] = useState(false)
	const [doneEleven, setDoneEleven] = useState(false)

	const copy = async (data, setter) => {
		setter(true)
		await copyToClipboard(data)

		setTimeout(() => {
			setter(false)
		}, 800)
	}
	const findExchange = useSelector(state => state.walletExchange.findExchange)

	useEffect(() => {
		setSocket(socketEndpoint)
	}, [])

	useEffect(() => {
		findExchangeMutation.mutate({
			exchangeId
		})
	}, [])

	const pushData = (data, tab) => {
		let dataArray = []
		dataArray = dataArray.concat(data)

		const uniqueArray = Array.from(new Set(dataArray.map(JSON.stringify))).map(
			JSON.parse
		)

		let findOn = uniqueArray.filter(obj => {
			if (obj.data.id === exchangeId) {
				return true
			}
		})

		if (findOn.length !== 0) {
			setFindTab(findOn[0])
			setActiveTab(tab)
		}
	}

	const handleRes = useCallback((data, tab) => {
		pushData(data, tab)
	}, [])

	useEffect(() => {
		if (findExchangeMutation.isSuccess) {
			dispatch(setFindExchange(findExchangeMutation?.data))

			if (findExchangeMutation?.data?.data?.userDepositStatus) {
				setActiveTab('2')
				socket.on('depositStatus', data => {
					handleRes(data, '3')
				})
			}
			if (findExchangeMutation?.data?.data?.adminFeeStatus) {
				setActiveTab('3')
				socket.on('depositStatus', data => {
					handleRes(data, '4')
				})
			}

			if (findExchangeMutation?.data?.data?.userMoveToAdminStatus) {
				setActiveTab('4')
				socket.on('adminTotoAddress', data => {
					handleRes(data, '4')
				})
			}

			if (findExchangeMutation?.data?.data?.adminSendToAddress) {
				socket.disconnect()
				setActiveTab('4')
			}
		}
	}, [findExchangeMutation.isSuccess])

	useEffect(() => {
		if (findExchangeMutation.isSuccess) {
			dispatch(setFindExchange(findExchangeMutation?.data))

			if (findExchangeMutation?.data?.data?.userDepositStatus === false) {
				socket.on('depositStatus', data => {
					if (data.status) {
						if (data?.data?.userDepositStatus) {
							handleRes(data, '2')
						}
						if (data?.data?.adminFeeStatus) {
							handleRes(data, '3')
						}
						if (data?.data?.userMoveToAdminStatus) {
							handleRes(data, '4')
						}
					}
					if (data.message === 'Check Your Deposit Amount') {
					}
				})
			}

			if (findTab && findTab.data.userMoveToAdminStatus) {
				socket.on('adminTotoAddress', data => {
					if (data.status) {
						handleRes(data, '4')
					}
				})
			}

			if (findTab && findTab.data.adminSendToAddress) {
				socket.disconnect()
				setActiveTab('4')
			}
		}
	}, [findExchangeMutation.isSuccess, findTab])

	return (
		<Fragment>
			<Header />
			<div className='pagemain-content'>
				<section className='bridge-sec depositCnt'>
					<div className='container'>
						<div className='row'>
							<div className='col-lg-5'>
								<div className='bridge-maincnt'>
									<div className='bridge-body'>
										<div className='d-flex justify-content-between align-items-baseline'>
											<div>
												<h3 className='mdHeading'>
													{t('Awaiting your deposit')}
												</h3>
												<span className='blueTxt smHeading'>
													{t('Exchange Id')} : {exchangeId}
												</span>
											</div>
											<Link to='/contact' className='sprtUs'>
												<img src={qustImg} className='mr-1' />
												{t('Support Us')}
											</Link>
										</div>

										<form className='mt-5'>
											<div className='form-row'>
												<div className='col-12 mb-3'>
													<label className='form-label'>
														{t('Send Deposit')}
													</label>
													<div className=''>
														<div className='SndDptDv'>
															<span>
																<img
																	src={
																		findExchange
																			? findExchange?.data?.fromcoinImage
																			: ''
																	}
																	alt=''
																	className='img-fluid'
																/>
																{findExchange ? (
																	<>
																		{findExchange &&
																			findExchange?.data?.fromAmount}{' '}
																		{findExchange &&
																			findExchange?.data?.fromcoinName}{' '}
																		(
																		{findExchange &&
																			findExchange?.data?.fromcoinType}
																		)
																	</>
																) : (
																	<>{t('Loading...')}</>
																)}
															</span>
														</div>
													</div>
												</div>

												<div className='col-12'>
													<label className='form-label'>
														{t('Deposit address')}
													</label>
													<div className='d-flex flex-wrap'>
														<div className='qrCodeCnt mr-2'>
															<img
																src={
																	findExchange
																		? findExchange?.QRCodeUrl
																		: loading
																}
																className='img-fluid rounded-lg'
																alt=''
															/>
														</div>
														<div className='qrAddr d-flex flex-column'>
															{findExchange ? (
																<>
																	<span>
																		{findExchange
																			? findExchange?.data?.fromAddress
																			: null}
																	</span>
																	<div className='d-flex'>
																		<a
																			href={explorerRedirect(
																				findExchange &&
																				findExchange?.data?.fromAddress,
																				'address',
																				findExchange &&
																				findExchange?.data?.fromNetwork
																			)}
																			target='_blank'
																		>
																			<img
																				src={linkIcon}
																				alt=''
																				className='img-fluid mr-2'
																			/>
																		</a>
																		<Link to=''>
																			{done ? (
																				<div>
																					<img
																						src={Success}
																						alt=''
																						className='img-fluid'
																					/>
																				</div>
																			) : (
																				<div
																					onClick={() =>
																						copy(
																							findExchange?.data.fromAddress,
																							setDone
																						)
																					}
																				>
																					<img
																						src={copyIcon}
																						alt=''
																						className='img-fluid'
																					/>
																				</div>
																			)}
																		</Link>
																	</div>
																</>
															) : (
																<span>{t('Loading...')}</span>
															)}
														</div>
													</div>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
							<div className='col-lg-7 d-flex'>
								<div className='tabCnt w-100'>
									<div className='tabHead'>
										{(findTab && findTab?.data?.adminSendToAddress) ||
											findExchangeMutation?.data?.data
												?.adminSendToAddress ? (
											<div className='d-flex justify-content-center align-items-center'>
												<div className='text-center'>
													<img src={Finished} height='62' />
													<h3>{t('Finished Successfully')}</h3>
												</div>
											</div>
										) : (
											<Nav tabs>
												<NavItem>
													<NavLink
														className={classnames({
															active:
																activeTab === '1' ||
																findExchangeMutation?.data?.data
																	?.userDepositStatus ||
																(findTab &&
																	findTab.data.userDepositStatus === true)
														})}
														disabled
													>
														<div className='iconCnt'>
															{findExchangeMutation?.data?.data
																?.userDepositStatus ||
																(findTab &&
																	findTab.data.userDepositStatus === true) ? (
																<img src={tickIcon} className='tabIcon' />
															) : (
																<img src={pendingDeposit} className='tabIcon' />
															)}
														</div>
														<h3 className='linkHeading'>
															{t('Pending Deposit')}
														</h3>
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														className={classnames({
															active:
																activeTab === '2' ||
																findExchangeMutation?.data?.data
																	?.adminFeeStatus ||
																(findTab &&
																	findTab.data.adminFeeStatus === true)
														})}
														disabled
													>
														<div className='iconCnt'>
															{findExchangeMutation?.data?.data
																?.adminFeeStatus ||
																(findTab &&
																	findTab.data.adminFeeStatus === true) ? (
																<img src={tickIcon} className='tabIcon' />
															) : (
																<img src={confirming} className='tabIcon' />
															)}
														</div>
														<h3 className='linkHeading'>{t('Confirming')}</h3>
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														className={classnames({
															active:
																activeTab === '3' ||
																findExchangeMutation?.data?.data
																	?.userMoveToAdminStatus ||
																(findTab &&
																	findTab.data.userMoveToAdminStatus === true)
														})}
														disabled
													>
														<div className='iconCnt'>
															{findExchangeMutation?.data?.data
																?.userMoveToAdminStatus ||
																(findTab &&
																	findTab.data.userMoveToAdminStatus === true) ? (
																<img src={tickIcon} className='tabIcon' />
															) : (
																<img src={exchangeIcon} className='tabIcon' />
															)}
														</div>
														<h3 className='linkHeading'>{t('Exchange')}</h3>
													</NavLink>
												</NavItem>
												<NavItem>
													<NavLink
														className={classnames({
															active:
																activeTab === '4' ||
																findExchangeMutation?.data?.data
																	?.adminSendToAddress ||
																(findTab &&
																	findTab.data.adminSendToAddress === true)
														})}
														disabled
													>
														<div className='iconCnt'>
															{findExchangeMutation?.data?.data
																?.adminSendToAddress ||
																(findTab &&
																	findTab.data.adminSendToAddress === true) ? (
																<img src={tickIcon} className='tabIcon' />
															) : (
																<img src={sending} className='tabIcon' />
															)}
														</div>
														<h3 className='linkHeading'>{t('Sending')}</h3>
													</NavLink>
												</NavItem>
											</Nav>
										)}
									</div>
									<div className='tabBody'>
										<TabContent activeTab={activeTab}>
											<TabPane tabId='1'>
												<h3 className='heading mb-4'>
													{t('Operation Details')}
												</h3>
												<div className='operationRow'>
													<span className='operationLabel'>{t('You get')}</span>
													<div className='d-flex align-items-center flex-wrap'>
														<span className='addr mr-3'>
															{findExchange ? (
																<>
																	{findExchange && findExchange?.data?.toAmount}{' '}
																	{findExchange &&
																		findExchange?.data?.tocoinName}{' '}
																	(
																	{findExchange &&
																		findExchange?.data?.tocoinType}
																	)
																</>
															) : (
																<>{t('Loading...')}</>
															)}
														</span>
													</div>
												</div>
												<div className='operationRow mb-4'>
													<span className='operationLabel'>
														{t('Recipient address')}
													</span>
													<div className='d-flex align-items-center flex-wrap'>
														{findExchange ? (
															<>
																<span className='addr mr-3'>
																	{findExchange
																		? findExchange?.data?.toAddress
																		: null}
																</span>
																<a
																	href={explorerRedirect(
																		findExchange &&
																		findExchange?.data?.toAddress,
																		'address',
																		findExchange &&
																		findExchange?.data?.toNetwork
																	)}
																	target='_blank'
																>
																	<img
																		src={linkIcon}
																		alt=''
																		className='img-fluid mr-2'
																	/>
																</a>
																<Link to=''>
																	{doneOne ? (
																		<div>
																			<img
																				src={Success}
																				alt=''
																				className='img-fluid'
																			/>
																		</div>
																	) : (
																		<div
																			onClick={() =>
																				copy(
																					findExchange?.data?.toAddress,
																					setDoneOne
																				)
																			}
																		>
																			<img
																				src={copyIcon}
																				alt=''
																				className='img-fluid'
																			/>
																		</div>
																	)}
																</Link>
															</>
														) : (
															<span className='text-white'>
																{t('Loading...')}
															</span>
														)}
													</div>
												</div>
											</TabPane>
											<TabPane tabId='2'>
												<h3 className='heading mb-4'>
													{t('Operation Details')}
												</h3>
												<div className='operationRow'>
													<span className='operationLabel'>{t('You get')}</span>
													<div className='d-flex align-items-center flex-wrap'>
														<span className='addr mr-3'>
															{findExchange ? (
																<>
																	{findExchange && findExchange?.data?.toAmount}{' '}
																	{findExchange &&
																		findExchange?.data?.tocoinName}{' '}
																	(
																	{findExchange &&
																		findExchange?.data?.tocoinType}
																	)
																</>
															) : (
																<>{t('Loading...')}</>
															)}
														</span>
													</div>
												</div>
												<div className='operationRow mb-4'>
													<span className='operationLabel'>
														{t('Recipient address')}
													</span>
													<div className='d-flex align-items-center flex-wrap'>
														<span className='addr mr-3'>
															{findExchange && findExchange?.data?.toAddress}
														</span>
														<a
															href={explorerRedirect(
																findExchange && findExchange?.data?.toAddress,
																'address',
																findExchange && findExchange?.data?.toNetwork
															)}
															target='_blank'
														>
															<img
																src={linkIcon}
																alt=''
																className='img-fluid mr-2'
															/>
														</a>
														<Link to=''>
															{doneTwo ? (
																<div>
																	<img
																		src={Success}
																		alt=''
																		className='img-fluid'
																	/>
																</div>
															) : (
																<div
																	onClick={() =>
																		copy(
																			findExchange?.data?.toAddress,
																			setDoneTwo
																		)
																	}
																>
																	<img
																		src={copyIcon}
																		alt=''
																		className='img-fluid'
																	/>
																</div>
															)}
														</Link>
													</div>
												</div>
												<div className='operationRow'>
													<span className='operationLabel'>
														{t('You Send')}
													</span>
													<div className='d-flex align-items-center flex-wrap'>
														<span className='addr mr-3'>
															{findExchange?.data?.fromAmount !== ''
																? findExchange?.data?.fromAmount
																: findTab !== ''
																	? findTab?.data.fromAmount
																	: ''}{' '}
															{findExchange?.data?.fromcoinName !== ''
																? findExchange?.data?.fromcoinName
																: ''}{' '}
															(
															{findExchange?.data?.fromcoinType !== ''
																? findExchange?.data?.fromcoinType
																: ''}
															)
														</span>
													</div>
												</div>
												<div className='operationRow'>
													<span className='operationLabel'>
														{t('Deposit')} {t('address')}
													</span>
													<div className='d-flex align-items-center flex-wrap'>
														<span className='addr mr-3'>
															{formatAddress(
																findExchange?.data?.fromAddress !== ''
																	? findExchange?.data?.fromAddress
																	: findTab && findTab?.data.fromAddress
															)}
														</span>
														<a
															href={explorerRedirect(
																findExchange?.data?.fromAddress !== ''
																	? findExchange?.data?.fromAddress
																	: findTab && findTab?.data.fromAddress,
																'address',
																findExchange && findExchange?.data?.fromNetwork
															)}
															target='_blank'
														>
															<img
																src={linkIcon}
																alt=''
																className='img-fluid mr-2'
															/>
														</a>
														<Link to=''>
															{doneThree ? (
																<div>
																	<img
																		src={Success}
																		alt=''
																		className='img-fluid'
																	/>
																</div>
															) : (
																<div
																	onClick={() =>
																		copy(
																			findExchange?.data?.fromAddress,
																			setDoneThree
																		)
																	}
																>
																	<img
																		src={copyIcon}
																		alt=''
																		className='img-fluid'
																	/>
																</div>
															)}
														</Link>
													</div>
												</div>
												{(findTab && findTab.data.userDepositStatus) ||
													findExchangeMutation?.data?.data
														?.userDepositStatus ? (
													<div className='operationRow'>
														<span className='operationLabel'>
															{t('Hash In')}
														</span>
														<div className='d-flex align-items-center flex-wrap'>
															<span className='addr mr-3'>
																{findExchange?.data?.hashIn !== ''
																	? formatAddress(findExchange?.data?.hashIn)
																	: formatAddress(
																		findTab && findTab?.data?.hash
																	)}
															</span>
															<a
																href={explorerRedirect(
																	findExchange?.data?.hashIn !== ''
																		? findExchange?.data?.hashIn
																		: findTab && findTab?.data?.hash,
																	findExchange &&
																		findExchange?.data?.fromNetwork !== 'TRX'
																		? 'tx'
																		: 'transaction',
																	findExchange &&
																	findExchange?.data?.fromNetwork
																)}
																target='_blank'
															>
																<img
																	src={linkIcon}
																	alt=''
																	className='img-fluid mr-2'
																/>
															</a>
															<Link to=''>
																{doneFour ? (
																	<div>
																		<img
																			src={Success}
																			alt=''
																			className='img-fluid'
																		/>
																	</div>
																) : (
																	<div
																		onClick={() =>
																			copy(
																				findExchange?.data?.hashIn !== ''
																					? findExchange?.data?.hashIn
																					: findTab && findTab?.data?.hash,
																				setDoneFour
																			)
																		}
																	>
																		<img
																			src={copyIcon}
																			alt=''
																			className='img-fluid'
																		/>
																	</div>
																)}
															</Link>
														</div>
													</div>
												) : null}
											</TabPane>
											<TabPane tabId='3'>
												<h3 className='heading mb-4'>
													{t('Operation Details')}
												</h3>
												<div className='operationRow'>
													<span className='operationLabel'>{t('You get')}</span>
													<div className='d-flex align-items-center flex-wrap'>
														<span className='addr mr-3'>
															{findExchange ? (
																<>
																	{findExchange && findExchange?.data?.toAmount}{' '}
																	{findExchange &&
																		findExchange?.data?.tocoinName}{' '}
																	(
																	{findExchange &&
																		findExchange?.data?.tocoinType}
																	)
																</>
															) : (
																<>{t('Loading...')}</>
															)}
														</span>
													</div>
												</div>
												<div className='operationRow mb-4'>
													<span className='operationLabel'>
														{t('Recipient address')}
													</span>
													<div className='d-flex align-items-center flex-wrap'>
														<span className='addr mr-3'>
															{findExchange && findExchange?.data?.toAddress}
														</span>
														<a
															href={explorerRedirect(
																findExchange && findExchange?.data?.toAddress,
																'address',
																findExchange && findExchange?.data?.toNetwork
															)}
															target='_blank'
														>
															<img
																src={linkIcon}
																alt=''
																className='img-fluid mr-2'
															/>
														</a>
														<Link to=''>
															{doneFive ? (
																<div>
																	<img
																		src={Success}
																		alt=''
																		className='img-fluid'
																	/>
																</div>
															) : (
																<div
																	onClick={() =>
																		copy(
																			findExchange?.data?.toAddress,
																			setDoneFive
																		)
																	}
																>
																	<img
																		src={copyIcon}
																		alt=''
																		className='img-fluid'
																	/>
																</div>
															)}
														</Link>
													</div>
												</div>
												<div className='operationRow'>
													<span className='operationLabel'>
														{t('You Send')}
													</span>
													<div className='d-flex align-items-center flex-wrap'>
														<span className='addr mr-3'>
															{findExchange?.data?.fromAmount !== ''
																? findExchange?.data?.fromAmount
																: findTab !== ''
																	? findTab?.data.fromAmount
																	: ''}{' '}
															{findExchange?.data?.fromcoinName !== ''
																? findExchange?.data?.fromcoinName
																: ''}{' '}
															(
															{findExchange?.data?.fromcoinType !== ''
																? findExchange?.data?.fromcoinType
																: ''}
															)
														</span>
													</div>
												</div>
												<div className='operationRow'>
													<span className='operationLabel'>
														{t('Deposit')} {t('address')}
													</span>
													<div className='d-flex align-items-center flex-wrap'>
														<span className='addr mr-3'>
															{formatAddress(
																findExchange?.data?.fromAddress !== ''
																	? findExchange?.data?.fromAddress
																	: findTab && findTab?.data.fromAddress
															)}
														</span>
														<a
															href={explorerRedirect(
																findExchange?.data?.fromAddress !== ''
																	? findExchange?.data?.fromAddress
																	: findTab && findTab?.data.fromAddress,
																'address',
																findExchange && findExchange?.data?.fromNetwork
															)}
															target='_blank'
														>
															<img
																src={linkIcon}
																alt=''
																className='img-fluid mr-2'
															/>
														</a>
														<Link to=''>
															{doneSix ? (
																<div>
																	<img
																		src={Success}
																		alt=''
																		className='img-fluid'
																	/>
																</div>
															) : (
																<div
																	onClick={() =>
																		copy(
																			findExchange?.data?.fromAddress,
																			setDoneSix
																		)
																	}
																>
																	<img
																		src={copyIcon}
																		alt=''
																		className='img-fluid'
																	/>
																</div>
															)}
														</Link>
													</div>
												</div>
												{(findTab && findTab.data.userDepositStatus) ||
													findExchangeMutation?.data?.data
														?.userDepositStatus ? (
													<div className='operationRow'>
														<span className='operationLabel'>
															{t('Hash In')}
														</span>
														<div className='d-flex align-items-center flex-wrap'>
															<span className='addr mr-3'>
																{formatAddress(
																	findExchange?.data?.hashIn !== ''
																		? findExchange?.data?.hashIn
																		: findTab && findTab?.data?.hash
																)}
															</span>
															<a
																href={explorerRedirect(
																	findExchange?.data?.hashIn !== ''
																		? findExchange?.data?.hashIn
																		: findTab && findTab?.data?.hash,
																	findExchange &&
																		findExchange?.data?.fromNetwork !== 'TRX'
																		? 'tx'
																		: 'transaction',
																	findExchange &&
																	findExchange?.data?.fromNetwork
																)}
																target='_blank'
															>
																<img
																	src={linkIcon}
																	alt=''
																	className='img-fluid mr-2'
																/>
															</a>
															<Link to=''>
																{doneSeven ? (
																	<div>
																		<img
																			src={Success}
																			alt=''
																			className='img-fluid'
																		/>
																	</div>
																) : (
																	<div
																		onClick={() =>
																			copy(
																				findExchange?.data?.hashIn !== ''
																					? findExchange?.data?.hashIn
																					: findTab && findTab?.data.hash,
																				setDoneSeven
																			)
																		}
																	>
																		<img
																			src={copyIcon}
																			alt=''
																			className='img-fluid'
																		/>
																	</div>
																)}
															</Link>
														</div>
													</div>
												) : null}
											</TabPane>
											<TabPane tabId='4'>
												<h3 className='heading mb-4'>
													{t('Operation Details')}
												</h3>
												<div className='operationRow'>
													<span className='operationLabel'>{t('You get')}</span>
													<div className='d-flex align-items-center flex-wrap'>
														<span className='addr mr-3'>
															{findExchange ? (
																<>
																	{findExchange && findExchange?.data?.toAmount}{' '}
																	{findExchange &&
																		findExchange?.data?.tocoinName}{' '}
																	(
																	{findExchange &&
																		findExchange?.data?.tocoinType}
																	)
																</>
															) : (
																<>{t('Loading...')}</>
															)}
														</span>
													</div>
												</div>
												<div className='operationRow'>
													<span className='operationLabel'>
														{t('Recipient address')}
													</span>
													<div className='d-flex align-items-center flex-wrap'>
														<span className='addr mr-3'>
															{findExchange && findExchange?.data?.toAddress}
														</span>
														<a
															href={explorerRedirect(
																findExchange && findExchange?.data?.toAddress,
																'address',
																findExchange && findExchange?.data?.toNetwork
															)}
															target='_blank'
														>
															<img
																src={linkIcon}
																alt=''
																className='img-fluid mr-2'
															/>
														</a>
														<Link to=''>
															{doneEight ? (
																<div>
																	<img
																		src={Success}
																		alt=''
																		className='img-fluid'
																	/>
																</div>
															) : (
																<div
																	onClick={() =>
																		copy(
																			findExchange?.data?.toAddress,
																			setDoneEight
																		)
																	}
																>
																	<img
																		src={copyIcon}
																		alt=''
																		className='img-fluid'
																	/>
																</div>
															)}
														</Link>
													</div>
												</div>
												{(findTab && findTab.data.adminSendToAddress) ||
													findExchangeMutation?.data?.data
														?.adminSendToAddress ? (
													<div className='operationRow mb-4'>
														<span className='operationLabel'>
															{t('Hash Out')}
														</span>
														<div className='d-flex align-items-center flex-wrap'>
															<span className='addr mr-3'>
																{formatAddress(
																	findExchange?.data?.hashOut !== ''
																		? findExchange?.data?.hashOut
																		: findTab && findTab?.data?.hashOut
																)}
															</span>
															<a
																href={explorerRedirect(
																	findExchange?.data?.hashOut !== ''
																		? findExchange?.data?.hashOut
																		: findTab && findTab?.data?.hashOut,
																	findExchange &&
																		findExchange?.data?.toNetwork !== 'TRX'
																		? 'tx'
																		: 'transaction',
																	findExchange && findExchange?.data?.toNetwork
																)}
																target='_blank'
															>
																<img
																	src={linkIcon}
																	alt=''
																	className='img-fluid mr-2'
																/>
															</a>
															<Link to=''>
																{doneNine ? (
																	<div>
																		<img
																			src={Success}
																			alt=''
																			className='img-fluid'
																		/>
																	</div>
																) : (
																	<div
																		onClick={() =>
																			copy(
																				findExchange?.data?.hashOut !== ''
																					? findExchange?.data?.hashOut
																					: findTab && findTab?.data?.hashOut,
																				setDoneNine
																			)
																		}
																	>
																		<img
																			src={copyIcon}
																			alt=''
																			className='img-fluid'
																		/>
																	</div>
																)}
															</Link>
														</div>
													</div>
												) : null}
												<div className='operationRow'>
													<span className='operationLabel'>
														{t('You Send')}
													</span>
													<div className='d-flex align-items-center flex-wrap'>
														<span className='addr mr-3'>
															{findExchange?.data?.fromAmount !== ''
																? findExchange?.data?.fromAmount
																: findTab !== ''
																	? findTab?.data.fromAmount
																	: ''}{' '}
															{findExchange?.data?.fromcoinName !== ''
																? findExchange?.data?.fromcoinName
																: ''}{' '}
															(
															{findExchange?.data?.fromcoinType !== ''
																? findExchange?.data?.fromcoinType
																: ''}
															)
														</span>
													</div>
												</div>
												<div className='operationRow'>
													<span className='operationLabel'>
														{t('Deposit address')}
													</span>
													<div className='d-flex align-items-center flex-wrap'>
														<span className='addr mr-3'>
															{formatAddress(
																findExchange?.data?.fromAddress !== ''
																	? findExchange?.data?.fromAddress
																	: findTab && findTab?.data.fromAddress
															)}
														</span>
														<a
															href={explorerRedirect(
																findExchange?.data?.fromAddress !== ''
																	? findExchange?.data?.fromAddress
																	: findTab && findTab?.data.fromAddress,
																'address',
																findExchange && findExchange?.data?.fromNetwork
															)}
															target='_blank'
														>
															<img
																src={linkIcon}
																alt=''
																className='img-fluid mr-2'
															/>
														</a>
														<Link to=''>
															{doneTen ? (
																<div>
																	<img
																		src={Success}
																		alt=''
																		className='img-fluid'
																	/>
																</div>
															) : (
																<div
																	onClick={() =>
																		copy(
																			findExchange?.data?.fromAddress,
																			setDoneTen
																		)
																	}
																>
																	<img
																		src={copyIcon}
																		alt=''
																		className='img-fluid'
																	/>
																</div>
															)}
														</Link>
													</div>
												</div>
												{(findTab && findTab.data.userDepositStatus) ||
													findExchangeMutation?.data?.data
														?.userDepositStatus ? (
													<div className='operationRow'>
														<span className='operationLabel'>
															{t('Hash In')}
														</span>
														<div className='d-flex align-items-center flex-wrap'>
															<span className='addr mr-3'>
																{formatAddress(
																	findExchange?.data?.hashIn !== ''
																		? findExchange?.data?.hashIn
																		: findTab && findTab?.data?.hash
																)}
															</span>
															<a
																href={explorerRedirect(
																	findExchange?.data?.hashIn !== ''
																		? findExchange?.data?.hashIn
																		: findTab && findTab?.data?.hash,
																	findExchange &&
																		findExchange?.data?.fromNetwork !== 'TRX'
																		? 'tx'
																		: 'transaction',
																	findExchange &&
																	findExchange?.data?.fromNetwork
																)}
																target='_blank'
															>
																<img
																	src={linkIcon}
																	alt=''
																	className='img-fluid mr-2'
																/>
															</a>
															<Link to=''>
																{doneEleven ? (
																	<div>
																		<img
																			src={Success}
																			alt=''
																			className='img-fluid'
																		/>
																	</div>
																) : (
																	<div
																		onClick={() =>
																			copy(
																				findExchange?.data?.hashIn !== ''
																					? findExchange?.data?.hashIn
																					: findTab && findTab?.data?.hash,
																				setDoneEleven
																			)
																		}
																	>
																		<img
																			src={copyIcon}
																			alt=''
																			className='img-fluid'
																		/>
																	</div>
																)}
															</Link>
														</div>
													</div>
												) : null}
											</TabPane>
										</TabContent>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
				<section className='faqSecCnt mt-5'>
					<div className='container'>
						<h3 className='secHeading'>{t('Have any questions')} ?</h3>
						<Accordion allowZeroExpanded>
							<AccordionItem>
								<AccordionItemHeading>
									<AccordionItemButton>
										{t('I sent a deposit, what’s next')} ?
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<p>
										{t(
											'Now all you have to do is wait. Please, no need to worry if you have already sent the funds and still see the same “Awaiting your deposit” status. As soon as the transaction is confirmed in the network, we’ll get your deposit, the exchange process will begin and the status will change.'
										)}
									</p>
								</AccordionItemPanel>
							</AccordionItem>
							<AccordionItem>
								<AccordionItemHeading>
									<AccordionItemButton>
										{t('How long does it take to exchange coins')} ?
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<p>
										{t(
											'The coin exchange time usually varies from 5 to 60 minutes. There are rare occasions when more time is needed. The processing speed depends on how long it takes for a block to be included in the network. Most operations proceed in just a few minutes.'
										)}
									</p>
								</AccordionItemPanel>
							</AccordionItem>
							<AccordionItem>
								<AccordionItemHeading>
									<AccordionItemButton>
										{t('What happens if I close the exchange page')} ?
									</AccordionItemButton>
								</AccordionItemHeading>
								<AccordionItemPanel>
									<p>
										{t(
											'If you want to check the status of your exchange and view the transaction history, we recommend you sign up and get a customer account. You can easily close the exchange page because all the information will be in your account.'
										)}
									</p>
								</AccordionItemPanel>
							</AccordionItem>
						</Accordion>
					</div>
				</section>
			</div>
			<Footer />
		</Fragment>
	)
}

export default Deposit
