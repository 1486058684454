import React, { Fragment } from 'react'
import thanksImg from '../../assets/images/thank.gif'
import { Link } from 'react-router-dom'
import Sidemenu from '../../common/Sidemenu/Sidemenu'
import { useVerifyEmail } from './api'
import Loader from '../../utils/loader'
import { useTranslation } from 'react-i18next'

const Verify = () => {
	const { t } = useTranslation()

	const verifyEmailMutation = useVerifyEmail()

	const activateLink = () => {
		const local = localStorage.getItem('email')
		verifyEmailMutation.mutate({ email: local })
	}
	return (
		<Fragment>
			<div className='BgltNone'>
				<div className='page-tot'>
					<div className='LogMainSection'>
						<div className='row align-items-center mx-0'>
							<Sidemenu />
							<div className='col-lg-6'>
								<div className='LognSetMain py-3'>
									<div className='LognRtMain'>
										<div className='ThnkImg'>
											<img src={thanksImg} alt='' className='img-fluid' />
										</div>
										<div className='LognHdd1 text-center mb-4'>
											<h4>{t('Verify your account')}</h4>
											<p>
												{t('Confirm your email address')} <br />
												<Link to='' className=''>
													{localStorage.getItem('email')}
												</Link>{' '}
												{t('to activate your account')}.
											</p>
										</div>
										<div className='text-center mt-4 pt-2'>
											<button
												className='btn btn-primary Btn215-43'
												onClick={activateLink}
											>
												{verifyEmailMutation.isLoading ? (
													<Loader />
												) : (
													<>{t('Resend')}</>
												)}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default Verify
