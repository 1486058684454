import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	fromWalletToken: '',
	initialExchange: '',
	findExchange: '',
	createExchange: ''
}

export const exchangeWallet = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setFromWalletToken(state, action) {
			state.fromWalletToken = action.payload
		},
		setInitialExchange(state, action) {
			state.initialExchange = action.payload
		},
		setFindExchange(state, action) {
			state.findExchange = action.payload
		},
		setCreateExchange(state, action) {
			state.createExchange = action.payload
		}
	}
})

export const {
	setFromWalletToken,
	setInitialExchange,
	setFindExchange,
	setCreateExchange
} = exchangeWallet.actions

export default exchangeWallet.reducer
