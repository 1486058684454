import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { axiosData } from '../../../utils/axios-utils'
import { useTranslation } from 'react-i18next'
import DataService from '../../../utils/DataService'
const { postRequest } = DataService()

const signUpUser = async loginData => {
	return postRequest('/user/register', { ...loginData })
}

export const useSignUpUser = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	return useMutation(signUpUser, {
		onSuccess: loginSuccessData => {
			if (loginSuccessData?.status === 400) {
				if (
					loginSuccessData?.message ===
					'User Already created , Please Login! '
				) {
					toast.error(t(loginSuccessData?.message))
					return navigate('/login')
				} else {
					toast.error(t(loginSuccessData?.message))
				}
			}

			if (loginSuccessData?.status === 200) {
				toast.success(
					t(
						'Registered successfully, Kindly check your mail for activation link'
					)
				)
				return navigate('/thanks')
			}

			if (loginSuccessData?.status === 402) {
				toast.error(t(loginSuccessData?.message))
			}
		}
	})
}
