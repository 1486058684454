import React, { Fragment } from 'react'
import logoImg from '../../assets/images/logo.png'
import pagenotfoundImg from '../../assets/images/pagenotfound.png'
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

const Pagenotfound = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	return (
		<Fragment>
			<div className='four0four-maincnt'>
				<div className='four-cnt'>
					<div>
						<Link to=''>
							<img src={logoImg} alt='' className='img-fluid' />
						</Link>
					</div>
					<div className='mt-5'>
						<img src={pagenotfoundImg} alt='' className='img-fluid' />
					</div>
					<div className='mt-5'>
						<h5>{t("Page Not Found")}</h5>
					</div>
					<div className='mt-3'>
						<Link className='btn btn-primary' onClick={() => navigate(-1)}>
							{t("Back to Home")}
						</Link>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default Pagenotfound
