import { useMutation } from 'react-query'
import { Request } from '../../../utils/axios-utils'

const userHistory = async data => {
	return Request({ url: '/user/LjdayK9BuW6L11YC2+XRY5lqnl3rnAfpTpNA6HzDz90=', method: 'post', data })
}

export const useUserHistory = () => {

	return useMutation(userHistory, {
		onSuccess: loginSuccessData => {
			if (loginSuccessData.data.status === 400) {
			}

			if (loginSuccessData.data.status === 200) {
			}

			if (loginSuccessData.data.status === false) {
			}
		}
	})
}
