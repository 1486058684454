import React, { Fragment, useEffect, useLayoutEffect, useState } from 'react'
import Header from '../../common/Header/Header'
import Footer from '../../common/Footer/Footer'
import leftarrImg from '../../assets/images/left-arr.svg'
import rightarrImg from '../../assets/images/right-arr.svg'
import srchImg from '../../assets/images/search.png'
import { useUserHistory } from './api'
import { tableFormatAddress } from '../../utils/toFixed'
import { useDebounce } from '../../utils/toFixed'
import { useNavigate } from 'react-router-dom'
import { explorerRedirect } from '../../utils/toFixed'
import Success from '../../assets/images/success.png'
import copyIcon from '../../assets/images/copyIcon.png'
import { copyToClipboard } from '../../utils/toFixed'
import { useTranslation } from 'react-i18next'
import { formatDateToDefaultLocale } from '../../utils/toFixed'

const History = () => {
	const { t } = useTranslation()

	const userHistoryMutation = useUserHistory()
	const navigate = useNavigate()
	const userHistory = userHistoryMutation?.data?.data?.data

	const [data, setData] = useState(userHistory)
	const [currentPage, setCurrentPage] = useState(1)
	const [rowsPerPage, setRowsPerPage] = useState(10)
	const [searchText, setSearchText] = useState()

	const debouncedSearchTerm = useDebounce(searchText, 800)

	useEffect(() => {
		userHistoryMutation.mutate({
			_id: localStorage.getItem('userid'),
			user_tracking_id: localStorage.getItem('user_tracking_id'),
			filter: debouncedSearchTerm,
			sortOrder: 'desc',
			pageIndex: 0,
			pageSize: 100,
			sortActive: 'dateTime'
		})
	}, [currentPage, rowsPerPage, debouncedSearchTerm])

	useEffect(() => {
		setData(userHistory)
	}, [userHistory])

	const handlePageClick = event => {
		setCurrentPage(Number(event.target.id))
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}

	const handleItemsPerPageChange = event => {
		setRowsPerPage(Number(event.target.value))
		setCurrentPage(1)
	}

	const totalPages = Math.ceil(data && data.length / rowsPerPage)
	const handlePrevClick = () => {
		setCurrentPage(prevPage => prevPage - 1)
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}

	const handleNextClick = () => {
		setCurrentPage(prevPage => prevPage + 1)
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}

	const searchingText = e => {
		e.preventDefault()
		setSearchText(e.target.value)
	}

	const [done1, setDone1] = useState(false)

	const copy = async data => {
		setDone1(data)
		await copyToClipboard(data)

		setTimeout(() => {
			setDone1()
		}, 800)
	}

	const rangeStart = (currentPage - 1) * rowsPerPage + 1
	const rangeEnd = Math.min(currentPage * rowsPerPage, data && data.length)

	const renderPagination = Array.from({ length: totalPages }, (_, index) => (
		<li
			key={index}
			className={`page-item${index + 1 === currentPage ? ' active' : ''}`}
		>
			<li className='page-link' id={index + 1} onClick={handlePageClick}>
				{index + 1}
			</li>
		</li>
	))

	return (
		<Fragment>
			<Header />
			<div className='pagemain-content'>
				<section className='histroy-sec'>
					<div className='container'>
						<div className='histroy-head'>
							<h5>{t('History')}</h5>
						</div>
						<div className='histroy-body'>
							<div className='histroy-tbl'>
								<div className='d-flex justify-content-between align-items-center flex-wrap'>
									<div className='showresult mb-2'>
										<p>{t('Showing')}</p>
										<div>
											<select
												name=''
												id='rowsPerPage'
												value={rowsPerPage}
												onChange={handleItemsPerPageChange}
											>
												<option value={5}>5</option>
												<option value={10}>10</option>
												<option value={20}>20</option>
											</select>
										</div>
										<p>
											{t('of')} {data && data.length} {t('results')}
										</p>
									</div>
									<div className='srchdiv mb-2'>
										<input
											type='text'
											className='form-control'
											placeholder={t(
												'Search by Exchange Id,Send & Receiver address'
											)}
											value={searchText}
											onChange={ev => searchingText(ev)}
										/>
										<img src={srchImg} alt='' />
									</div>
								</div>
								<div className='cmntbl'>
									<div className='table-responsive'>
										<table className='table table-borderless'>
											<thead>
												<tr>
													<th>{t('Exchange Id')}</th>
													<th>{t('Send Address')}</th>
													<th>{t('Receiver Address')}</th>
													<th>{t('From Currency')}</th>
													<th>{t('To Currency')}</th>
													<th>{t('Status')}</th>
													<th>{t('Date')}</th>
												</tr>
											</thead>
											<tbody>
												{userHistoryMutation.isLoading ? (
													<tr>
														<td colSpan='7' className='text-center'>
															{t('Loading...')}
														</td>
													</tr>
												) : (
													<>
														{data && data.length !== 0 ? (
															data
																.slice(
																	(currentPage - 1) * rowsPerPage,
																	currentPage * rowsPerPage
																)
																.map((n, index) => {
																	return (
																		<tr>
																			<td>
																				<span
																					className='hsyRoute'
																					onClick={() =>
																						navigate(`/deposit/${n.exchangeId}`)
																					}
																				>
																					{n.exchangeId}
																				</span>{' '}
																				{done1 === n.exchangeId ? (
																					<span>
																						<img
																							src={Success}
																							alt=''
																							className='img-fluid'
																						/>
																					</span>
																				) : (
																					<span
																						onClick={() => copy(n.exchangeId)}
																					>
																						<img
																							src={copyIcon}
																							alt=''
																							className='img-fluid'
																						/>
																					</span>
																				)}
																			</td>
																			<td>
																				<a
																					onClick={e => e.stopPropagation()}
																					href={() =>
																						explorerRedirect(
																							n.fromAddress,
																							'address',
																							n.fromNetwork
																						)
																					}
																					target='_blank'
																				>
																					{tableFormatAddress(n.fromAddress)}
																				</a>
																			</td>
																			<td>
																				<a
																					href={explorerRedirect(
																						n.toAddress,
																						'address',
																						n.toNetwork
																					)}
																					target='_blank'
																				>
																					{tableFormatAddress(n.toAddress)}
																				</a>
																			</td>
																			<td>
																				{n.fromAmount.includes('.')
																					? Number(n.fromAmount)
																					: n.fromAmount}{' '}
																				{n.fromcoinName} ({n.fromcoinType})
																			</td>
																			<td>
																				{n.toAmount.includes('.')
																					? Number(n.toAmount)
																					: n.toAmount}{' '}
																				{n.tocoinName} ({n.tocoinType})
																			</td>
																			{n.transactionStatus === 0 ? (
																				<td className='text-warning'>
																					Pending
																				</td>
																			) : n.transactionStatus === 1 ? (
																				<td className='text-success'>
																					Completed
																				</td>
																			) : n.transactionStatus === 2 ? (
																				<td className='text-danger'>Failed</td>
																			) : null}
																			<td>
																				{formatDateToDefaultLocale(n.updatedAt)}
																			</td>
																		</tr>
																	)
																})
														) : (
															<tr>
																<td colSpan='7' className='text-center'>
																	{t('No data found')}
																</td>
															</tr>
														)}
													</>
												)}
											</tbody>
										</table>
									</div>
								</div>
								{data && data.length !== 0 ? (
									<div className='entries-pagination'>
										<div className='entries-box'>
											<p>
												{t('Showing')} {rangeStart} {t('to')}{' '}
												{rangeEnd ? rangeEnd : <>{t('Loading...')}</>} -{' '}
												{userHistory && userHistory.length} {t('entries')}
											</p>
										</div>
										<div className='tbl-pagination'>
											<ul>
												<li
													className={`page-item${currentPage === 1 ? ' disabled' : ''
														}`}
												>
													<button
														className='page-link'
														onClick={handlePrevClick}
													>
														<img
															src={leftarrImg}
															alt=''
															className='img-fluid'
															onClick={handlePrevClick}
														/>
													</button>
												</li>
												{renderPagination}
												<li
													className={`page-item${currentPage === totalPages ? ' disabled' : ''
														}`}
												>
													<button
														className='page-link'
														onClick={handleNextClick}
													>
														<img
															src={rightarrImg}
															alt=''
															className='img-fluid'
															onClick={handleNextClick}
														/>
													</button>
												</li>
											</ul>
										</div>
									</div>
								) : null}
							</div>
						</div>
					</div>
				</section>
			</div>
			<Footer />
		</Fragment>
	)
}

export default History
