import axios from 'axios'

export var url = 'https://apinesjs.neo-swap.io'

export const axiosData = axios.create({
	baseURL: url
})

export const Request = ({ ...options }) => {
	const token = localStorage.getItem('authToken')
	if (token) {
		axiosData.defaults.headers.common.Authorization = `Bearer ${token}`
	} else {
		delete axiosData.defaults.headers.common.Authorization
	}

	const onSuccess = response => response

	const onError = error => {
		return error
	}

	return axiosData(options).then(onSuccess).catch(onError)
}