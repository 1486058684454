import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import main from '../../assets/images/main.png'
import logo from '../../assets/images/logo.png'
import { useSocialLink } from '../../common/SocialLink/api'

const UnderMaintenance = () => {
	const navigate = useNavigate()

	const socialLinkMutation = useSocialLink()

	const socialMutation = async () => {
		await socialLinkMutation.mutate()
	}
	useEffect(() => {
		socialMutation()
	}, []) 

	const fetchCronData = () => {
		if (socialLinkMutation.isSuccess) {
			if (socialLinkMutation?.data?.data?.isCronRun) {
				navigate('/undermaintainence')
			} else {
				navigate('/')
			}
		}
	}

	useEffect(() => {
		fetchCronData()
	}, [socialLinkMutation.isSuccess])

	return (
		<Fragment>
			<div class='four0four-maincnt maintenancebg'>
				<div class='four-cnt'>
					<div className='container'>
						<div className='row align-items-center'>
							<div className='col-lg-6'>
								<img src={main} className='img-fluid' />
							</div>
							<div className='col-lg-6'>
								<img src={logo} className='img-fluid' />
								<h3 className='tit'>
									website
									<br />
									<span>under Maintenance</span>
								</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default UnderMaintenance
