import React from 'react'
import Header from '../../common/Header/Header'
import Footer from '../../common/Footer/Footer'
import { useCmsList } from './api'

const About = () => {
	const CmsData = useCmsList()

	return (
		<>
			<Header />
			<div className='pagemain-content'>
				<section className='bridge-sec'>
					<div className='container'>
						<div className='bridge-head text-center mb-3'>
							<h5>About Us</h5>
						</div>
						<div
							className='AbtHddCnt mb-5'
							dangerouslySetInnerHTML={{
								__html: CmsData?.data?.data[0].content
							}}
						></div>
						<div className='bridge-maincnt AbtKeyMain'>
							<div className='bridge-body pt-1'>
								<div className='KeyFeatHdd text-center mb-3'>
									<h4>Key Features</h4>
								</div>
								<div
									className='KeyFeatCntItm'
									dangerouslySetInnerHTML={{
										__html: CmsData?.data?.data[2].content
									}}
								></div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<Footer />
		</>
	)
}

export default About
