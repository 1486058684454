import React from 'react'
import loginlogoImg from '../../assets/images/login-logo.svg'
import { Link } from 'react-router-dom'
import SocialLink from '../SocialLink/SocialLink'
import { useTranslation } from 'react-i18next'

const Sidemenu = () => {
	const { t } = useTranslation()

	return (
		<div className='col-lg-6 LognLftBg'>
			<div className='LognSetMain'>
				<div className='LognLftMain'>
					<div className='LognLftLogo text-center'>
						<Link to='/' className='navbar-brand m-0 p-0'>
							<img src={loginlogoImg} alt='' className='img-fluid' />
						</Link>
					</div>
					<div className='FtrCpyRt'>
						<div className='ftr-social mb-4 pb-2'>
							<SocialLink />
						</div>
						<p className='copy-txt'>
							© 2023 Neo Swap {t('All rights reserved')}.
						</p>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Sidemenu
