import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { axiosData } from '../../../utils/axios-utils'
import { useTranslation } from 'react-i18next'
import DataService from '../../../utils/DataService'
const { postRequest } = DataService()

const currencyList = async loginData => {
	return postRequest('/user/currency-prices')
}

export const useCurrencyList = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	return useMutation(currencyList, {
		onMutate: () => {},
		onError: error => {
			if (error.name === 'AxiosError') {
				toast.error(t('Server Down'))
				localStorage.clear()
				return navigate('/login')
			}
		},
		onSuccess: loginSuccessData => {
			if (loginSuccessData?.status === 400) {
				toast.error(t(loginSuccessData?.message))
			}

			if (loginSuccessData?.status === 401) {
				navigate('/404')
			}

			if (loginSuccessData?.status === 413) {
				navigate('/404')
			}

			if (loginSuccessData?.status === 200) {
			}

			if (loginSuccessData?.status === false) {
				toast.error(t(loginSuccessData?.message))
			}
			if (
				loginSuccessData?.message ===
				'The VPN connection is not allowed via a local proxy!!'
			) {
				navigate('/404')
			}
			if (loginSuccessData?.status === true) {
				toast.success(t(loginSuccessData?.message))
			}
		}
	})
}

const createAddress = async data => {
	return postRequest('/user/SqQ9VFNwOCHmBXM7JdB7Wmp7HAH7QI', data)
}

export const useCreateAddress = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	return useMutation(createAddress, {
		onSuccess: createAddress => {
			if (createAddress?.status === 400) {
				toast.error(t(createAddress?.message))
			}

			if (createAddress?.status === 413) {
				navigate('/404')
			}

			if (createAddress?.status === true) {
				localStorage.setItem(
					'exchangeId',
					createAddress?.data?.exchangeId
				)
			}

			if (createAddress?.status === false) {
				toast.error(t(createAddress?.message))
			}
			if (
				createAddress?.message ===
				'The VPN connection is not allowed via a local proxy!!'
			) {
				navigate('/404')
			}
		}
	})
}

const pairPrice = async data => {
	return postRequest('/user/getPairPrices', data)
}

export const usePairPrice = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	return useMutation(pairPrice, {
		onSuccess: pairPrice => {
			if (pairPrice?.status === 400) {
				toast.error(t(pairPrice?.message))
			}

			if (pairPrice?.status === true) {
				localStorage.setItem('exchangeId', pairPrice?.data?.exchangeId)
			}

			if (pairPrice?.status === false) {
				toast.error(t(pairPrice?.message))
			}
			if (
				pairPrice?.message ===
				'The VPN connection is not allowed via a local proxy!!'
			) {
				navigate('/404')
			}
		}
	})
}
