import React, { Fragment, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Loader from '../../utils/loader'
import { useResetPassword } from './api'
import { useParams } from 'react-router-dom'
import Sidemenu from '../../common/Sidemenu/Sidemenu'
import { useTranslation } from 'react-i18next'

const PASSWORD_REGEX =
	/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

const ResetPassword = props => {
	const formSchema = Yup.object().shape({
		password: Yup.string()
			.required('Enter Password')
			.min(8, 'Password must be atleast 8 characters')
			.matches(
				PASSWORD_REGEX,
				'Enter at least one uppercase letter, one lowercase letter, one number and one special character.'
			),
		confirmpassword: Yup.string()
			.required('Confirm Password is a required')
			.test('passwords-match', 'Passwords must match', function (value) {
				if (this.parent.password === value) {
					return this.parent.password === value
				}
			})
	})
	let { token } = useParams()
	const { t } = useTranslation()

	const formOptions = { resolver: yupResolver(formSchema) }
	const resetPasswordMutation = useResetPassword()

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm(formOptions)

	const [showPassword, setShowPassword] = useState(false)
	const [showConfirmPassword, setShowConfirmPassword] = useState(false)

	const onSubmit = data => {
		resetPasswordMutation.mutate({ _id: token, ...data })
		reset()
	}


	return (
		<Fragment>
			<div className='BgltNone'>
				<div className='page-tot'>
					<div className='LogMainSection'>
						<div className='row align-items-center mx-0'>
							<Sidemenu />
							<div className='col-lg-6'>
								<div className='LognSetMain py-3'>
									<div className='LognRtMain'>
										<div className='LognHdd text-center mb-5'>
											<h4>{t('Reset Password')}</h4>
											<p>
												{t(
													'Enter the email address associated with your account'
												)}
												.
												<br />
												{t(
													"We'll send you a password recovery link to reset your password"
												)}
												.
											</p>
										</div>
										<form className='LogForm' onSubmit={handleSubmit(onSubmit)}>
											<div className='input-group mb-3 InptGrp'>
												<input
													type={showPassword ? 'text' : 'password'}
													className='form-control'
													placeholder={t('Password')}
													aria-label="Recipient's username"
													aria-describedby='basic-addon2'
													{...register('password')}
													autoFocus
												/>
												<div
													className='input-group-append ml-0'
													onClick={() => setShowPassword(!showPassword)}
												>
													<span className='input-group-text' id='basic-addon2'>
														{!showPassword ? (
															<svg
																width='32'
																height='32'
																viewBox='0 0 32 32'
																fill='none'
																xmlns='http://www.w3.org/2000/svg'
															>
																<path
																	d='M13 16C13 16.7956 13.3161 17.5587 13.8787 18.1213C14.4413 18.6839 15.2044 19 16 19C16.7956 19 17.5587 18.6839 18.1213 18.1213C18.6839 17.5587 19 16.7956 19 16C19 15.2044 18.6839 14.4413 18.1213 13.8787C17.5587 13.3161 16.7956 13 16 13C15.2044 13 14.4413 13.3161 13.8787 13.8787C13.3161 14.4413 13 15.2044 13 16Z'
																	stroke='#2BF3FF'
																	strokeWidth='1.5'
																	strokeLinecap='round'
																	strokeLinejoin='round'
																/>
																<path
																	d='M29 16C25.5333 22 21.2 25 16 25C10.8 25 6.46667 22 3 16C6.46667 10 10.8 7 16 7C21.2 7 25.5333 10 29 16Z'
																	stroke='#2BF3FF'
																	strokeWidth='1.5'
																	strokeLinecap='round'
																	strokeLinejoin='round'
																/>
																<path d='M30 3L3 30' stroke='#2BF3FF' />
															</svg>
														) : (
															<svg
																width='32'
																height='32'
																viewBox='0 0 32 32'
																fill='none'
																xmlns='http://www.w3.org/2000/svg'
															>
																<path
																	d='M13 16C13 16.7956 13.3161 17.5587 13.8787 18.1213C14.4413 18.6839 15.2044 19 16 19C16.7956 19 17.5587 18.6839 18.1213 18.1213C18.6839 17.5587 19 16.7956 19 16C19 15.2044 18.6839 14.4413 18.1213 13.8787C17.5587 13.3161 16.7956 13 16 13C15.2044 13 14.4413 13.3161 13.8787 13.8787C13.3161 14.4413 13 15.2044 13 16Z'
																	stroke='#2BF3FF'
																	strokeWidth='1.5'
																	strokeLinecap='round'
																	strokeLinejoin='round'
																/>
																<path
																	d='M29 16C25.5333 22 21.2 25 16 25C10.8 25 6.46667 22 3 16C6.46667 10 10.8 7 16 7C21.2 7 25.5333 10 29 16Z'
																	stroke='#2BF3FF'
																	strokeWidth='1.5'
																	strokeLinecap='round'
																	strokeLinejoin='round'
																/>
															</svg>
														)}
													</span>
												</div>
											</div>
											{errors.password && (
												<p className='errorMsg'>{t(errors.password?.message)}</p>
											)}
											<div className='input-group mb-3 InptGrp'>
												<input
													type={showConfirmPassword ? 'text' : 'password'}
													className='form-control'
													placeholder={t('Confirm Password')}
													aria-label="Recipient's username"
													aria-describedby='basic-addon2'
													{...register('confirmpassword')}
												/>
												<div
													className='input-group-append ml-0'
													onClick={() =>
														setShowConfirmPassword(!showConfirmPassword)
													}
												>
													<span className='input-group-text' id='basic-addon2'>
														{!showConfirmPassword ? (
															<svg
																width='32'
																height='32'
																viewBox='0 0 32 32'
																fill='none'
																xmlns='http://www.w3.org/2000/svg'
															>
																<path
																	d='M13 16C13 16.7956 13.3161 17.5587 13.8787 18.1213C14.4413 18.6839 15.2044 19 16 19C16.7956 19 17.5587 18.6839 18.1213 18.1213C18.6839 17.5587 19 16.7956 19 16C19 15.2044 18.6839 14.4413 18.1213 13.8787C17.5587 13.3161 16.7956 13 16 13C15.2044 13 14.4413 13.3161 13.8787 13.8787C13.3161 14.4413 13 15.2044 13 16Z'
																	stroke='#2BF3FF'
																	strokeWidth='1.5'
																	strokeLinecap='round'
																	strokeLinejoin='round'
																/>
																<path
																	d='M29 16C25.5333 22 21.2 25 16 25C10.8 25 6.46667 22 3 16C6.46667 10 10.8 7 16 7C21.2 7 25.5333 10 29 16Z'
																	stroke='#2BF3FF'
																	strokeWidth='1.5'
																	strokeLinecap='round'
																	strokeLinejoin='round'
																/>
																<path d='M30 3L3 30' stroke='#2BF3FF' />
															</svg>
														) : (
															<svg
																width='32'
																height='32'
																viewBox='0 0 32 32'
																fill='none'
																xmlns='http://www.w3.org/2000/svg'
															>
																<path
																	d='M13 16C13 16.7956 13.3161 17.5587 13.8787 18.1213C14.4413 18.6839 15.2044 19 16 19C16.7956 19 17.5587 18.6839 18.1213 18.1213C18.6839 17.5587 19 16.7956 19 16C19 15.2044 18.6839 14.4413 18.1213 13.8787C17.5587 13.3161 16.7956 13 16 13C15.2044 13 14.4413 13.3161 13.8787 13.8787C13.3161 14.4413 13 15.2044 13 16Z'
																	stroke='#2BF3FF'
																	strokeWidth='1.5'
																	strokeLinecap='round'
																	strokeLinejoin='round'
																/>
																<path
																	d='M29 16C25.5333 22 21.2 25 16 25C10.8 25 6.46667 22 3 16C6.46667 10 10.8 7 16 7C21.2 7 25.5333 10 29 16Z'
																	stroke='#2BF3FF'
																	strokeWidth='1.5'
																	strokeLinecap='round'
																	strokeLinejoin='round'
																/>
															</svg>
														)}
													</span>
												</div>
											</div>
											{errors.confirmpassword && (
												<p className='errorMsg'>
													{t(errors.confirmpassword?.message)}
												</p>
											)}
											<div className='text-center mt-4 pt-2'>
												<button
													className='btn btn-primary Btn215-43'
													type='submit'
												>
													{resetPasswordMutation.isLoading ? (
														<Loader />
													) : (
														<>{t('Reset')}</>
													)}
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default ResetPassword
