import React, { Fragment, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useForgotPassword } from './api'
import Loader from '../../utils/loader'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import Sidemenu from '../../common/Sidemenu/Sidemenu'
import { useTranslation } from 'react-i18next'

const EMAILID_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

const Forgotpassword = () => {
	const { t } = useTranslation()

	const formSchema = Yup.object().shape({
		email: Yup.string()
			.required('Enter Email Id')
			.matches(EMAILID_REGEX, 'Enter a valid email')
	})

	const formOptions = { resolver: yupResolver(formSchema) }
	const forgotPasswordMutation = useForgotPassword()

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm(formOptions)

	const navigate = useNavigate()
	const onSubmit = data => {
		forgotPasswordMutation.mutate(data)
		reset()
	}

	useEffect(() => {
		const check = localStorage.getItem('authToken')
		if (check) {
			navigate(-1)
		}
	}, [])

	return (
		<Fragment>
			<div className='BgltNone'>
				<div className='page-tot'>
					<div className='LogMainSection'>
						<div className='row align-items-center mx-0'>
							<Sidemenu />
							<div className='col-lg-6'>
								<div className='LognSetMain py-3'>
									<div className='LognRtMain'>
										<div className='LognHdd text-center mb-4'>
											<h4>{t('Forgot Password')}</h4>
											{forgotPasswordMutation?.isSuccess ? (
												<p>
													{t('Check your email')}.{' '}
													{t('A message with a link to')} <br />
													{t('reset your password has been sent to')}
													<br />
													<Link to='' className=''>
														{forgotPasswordMutation?.variables.email}
													</Link>
												</p>
											) : (
												<p>
													{t(
														'Enter the email address associated with your account'
													)}
													. <br />{' '}
													{t("We'll send you a password recovery link to")}
													{t('reset your password.')}
												</p>
											)}
										</div>

										{forgotPasswordMutation?.isSuccess ? (
											<>
												<div className='text-center my-4 pt-2'>
													<button
														className='btn btn-primary Btn215-43'
														type='submit'
														onClick={e => {
															e.preventDefault()
															navigate('/login')
														}}
													>
														{t('Back to Login')}
													</button>
												</div>
											</>
										) : (
											<form
												className='LogForm'
												onSubmit={handleSubmit(onSubmit)}
											>
												<div className='mb-3'>
													<label
														htmlFor='e1'
														className='form-label sr-only'
													></label>
													<input
														type='email'
														className='form-control'
														id='e1'
														placeholder='Email Id'
														{...register('email')}
														autoFocus
													/>
												</div>
												{errors.email && (
													<p className='errorMsg'>{errors.email.message}</p>
												)}
												<div className='text-center my-4 pt-2'>
													<button
														className='btn btn-primary Btn215-43'
														type='submit'
													>
														{forgotPasswordMutation.isLoading ? (
															<Loader />
														) : (
															<>{t('Send Reset Link')}</>
														)}
													</button>
												</div>
												<div className='text-center'>
													<Link
														to={'..'}
														onClick={e => {
															e.preventDefault()
															navigate(-1)
														}}
														className='LnkTxt'
													>
														{t('Go Back')}
													</Link>
												</div>
											</form>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default Forgotpassword
