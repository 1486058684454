import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { axiosData } from '../../../utils/axios-utils'
import { useTranslation } from 'react-i18next'
import DataService from '../../../utils/DataService'
const { postRequest } = DataService()

const loginUser = async loginData => {
	localStorage.setItem('email', loginData.email)
	return postRequest('/user/login', { ...loginData })
}

export const useLoginUser = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	return useMutation(loginUser, {
		onSuccess: loginSuccessData => {
			if (loginSuccessData?.message === 'Email is Not Activated') {
				toast.error(
					t(
						'Your Email is not verified, Please check your mail & activate your email address'
					)
				)
				return navigate('/verifyemail')
			}
			if (loginSuccessData?.status === false) {
				toast.error(t(loginSuccessData?.message))
			}

			if (loginSuccessData?.status === 400) {
				toast.error(t(loginSuccessData.message))
			}

			if (loginSuccessData?.status === 413) {
				navigate('/404')
			}

			if (loginSuccessData?.status === 200) {
				toast.success(t('You have been logged in successfully'))
				localStorage.setItem('authToken', loginSuccessData?.token)
				localStorage.setItem('userid', loginSuccessData?._id)
				localStorage.setItem(
					'user_tracking_id',
					loginSuccessData?.user_tracking_id
				)

				return navigate('/')
			}
		}
	})
}

const verifyUser = async verifyData => {
	return postRequest(`user/activate_link/${verifyData}`)
}

export const useVerifyUser = () => {
	const { t } = useTranslation()

	return useMutation(verifyUser, {
		onMutate: () => {},
		onError: error => {},
		onSuccess: loginSuccessData => {
			if (loginSuccessData?.status === 400) {
			}

			if (loginSuccessData?.status === 200) {
				toast.success(t('Your Email has been verified successfully'))
			}

			if (loginSuccessData?.status === false) {
				toast.error(t(loginSuccessData.message))
			}
		}
	})
}
