import React, { Fragment, useState } from 'react'
import {
	Collapse,
	Navbar,
	NavbarBrand,
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem
} from 'reactstrap'
import { useNavigate } from 'react-router-dom'
import logoImg from '../../assets/images/logo.png'
import myaccImg from '../../assets/images/myacc.png'
import profileImg from '../../assets/images/profile.png'
import helpImg from '../../assets/images/help.png'
import logoutImg from '../../assets/images/logout.png'
import droparrImg from '../../assets/images/drop-arr.png'
import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

const Header = () => {
	const { t } = useTranslation()

	const [collapsed, setCollapsed] = useState(true)
	const toggleNavbar = () => setCollapsed(!collapsed)

	const token = localStorage.getItem('authToken')

	const navigate = useNavigate()

	const disconnect = () => {
		localStorage.setItem('authToken', '')
		setTimeout(() => {
			navigate('/')
			toast.success(t('Logged out successfully'))
		}, 800)
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		})
	}
	return (
		<Fragment>
			<header id='homeheader'>
				<div className='container'>
					<Navbar color='faded' light expand='lg'>
						<NavbarBrand href='/' className='m-0 p-0'>
							<img src={logoImg} alt='' className='img-fluid' />
						</NavbarBrand>
						<button
							className='navbar-toggler'
							type='button'
							onClick={toggleNavbar}
						>
							<span className='navbar-icon'></span>
							<span className='navbar-icon'></span>
							<span className='navbar-icon'></span>
						</button>
						<Collapse isOpen={!collapsed} navbar>
							<div className='header-menu nd_lagchg ml-auto'>
								<button
									className='btn btn-link mr-lg-3 '
									type='button'
									onClick={() => navigate('/contact')}
								>
									{t('Contact Us')}
								</button>
								<button
									className='btn btn-secondary mr-2'
									type='button'
									onClick={() => navigate('/about')}
								>
									{t('About Us')}
								</button>
								{!token && (
									<>
										<button
											className='btn btn-secondary'
											type='button'
											onClick={() => navigate('/login')}
										>
											{t('Login')}
										</button>
										<button
											className='btn btn-primary'
											type='button'
											onClick={() => navigate('/signup')}
										>
											{t('Get An Account')}
										</button>
									</>
								)}
								{token && (
									<div className='cusform-input'>
										<UncontrolledDropdown className='CustDrpDwn prodd'>
											<DropdownToggle>
												<div className='inputcoin-btn'>
													<div className='coinbtn-main'>
														<div className='myaccbg'>
															<img
																src={myaccImg}
																alt=''
																className='img-fluid'
															/>
														</div>
														<div className='mx-2'>
															<h5 className='mb-0'>{t('My Account')}</h5>
														</div>
														<div>
															<img src={droparrImg} />
														</div>
													</div>
												</div>
											</DropdownToggle>
											<DropdownMenu>
												<DropdownItem onClick={() => navigate('/myprofile')}>
													<div className='myaccbg mr-2'>
														<img
															src={profileImg}
															alt=''
															className='img-fluid DrpDwnImg'
														/>
													</div>
													{t('Profile')}
												</DropdownItem>
												<DropdownItem onClick={() => navigate('/history')}>
													<div className='myaccbg mr-2'>
														<img
															src={helpImg}
															alt=''
															className='img-fluid DrpDwnImg'
														/>
													</div>
													{t('History')}
												</DropdownItem>
												<DropdownItem onClick={disconnect}>
													<div className='myaccbg mr-2'>
														<img
															src={logoutImg}
															alt=''
															className='img-fluid DrpDwnImg'
														/>
													</div>
													{t('Logout')}
												</DropdownItem>
											</DropdownMenu>
										</UncontrolledDropdown>
									</div>
								)}
							</div>
						</Collapse>
					</Navbar>
				</div>
			</header>
		</Fragment>
	)
}

export default Header
