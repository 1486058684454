import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { axiosData } from '../../../utils/axios-utils'
import { useTranslation } from 'react-i18next'
import DataService from '../../../utils/DataService'
const { postRequest } = DataService()

const socialLink = async () => {
	return postRequest('/user/get_site_settings')
}

export const useSocialLink = () => {
	const { t } = useTranslation()

	return useMutation(socialLink, {
		onSuccess: loginSuccessData => {
			
			if (loginSuccessData?.status === 400) {
					toast.error(t(loginSuccessData?.message))
			}

			if (loginSuccessData?.status === 200) {
			}

			if (loginSuccessData?.status === false) {
					toast.error(t(loginSuccessData?.message))
			}
		}
	})
}
