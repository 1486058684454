import React, { Fragment } from 'react'
import thanksImg from '../../assets/images/thank.gif'
import { Link } from 'react-router-dom'
import Sidemenu from '../../common/Sidemenu/Sidemenu'
import { useTranslation } from 'react-i18next'

const Thanks = () => {
	const { t } = useTranslation()

	return (
		<Fragment>
			<div className='BgltNone'>
				<div className='page-tot'>
					<div className='LogMainSection'>
						<div className='row align-items-center mx-0'>
							<Sidemenu />
							<div className='col-lg-6'>
								<div className='LognSetMain py-3'>
									<div className='LognRtMain'>
										<div className='ThnkImg'>
											<img src={thanksImg} alt='' className='img-fluid' />
										</div>
										<div className='LognHdd1 text-center mb-4'>
											<h4>{t("Thanks")}!</h4>
											<p>
												{t("We've sent a confirmation email to")}
												<br />
												<Link to='' className=''>
													{localStorage.getItem('email')}
												</Link>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default Thanks
