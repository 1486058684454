import React, { useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { useChangePassword } from './api'
import { useTranslation } from 'react-i18next'

export const ChangePassword = () => {
	const { t } = useTranslation()

	const formSchema = Yup.object().shape({
		password: Yup.string().required('Enter Current Password'),
		newpassword: Yup.string()
			.required('Enter New Password')
			.notOneOf(
				[Yup.ref('currentPassword'), null],
				'You cannot use your Current Password as New Password'
			)
			.min(8, 'Password is too short - should be 8 chars minimum')
			.max(15, 'Password is too long - should be 15 chars minimum'),
		confirmpassword: Yup.string()
			.required('Enter Confirm Password')
			.oneOf([Yup.ref('newpassword'), null], "Passwords didn't match")
	})

	const formOptions = { resolver: yupResolver(formSchema) }

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm(formOptions)

	const changePasswordMutation = useChangePassword()

	const [showCurrentPassword, setShowCurrentPassword] = useState(false)
	const [showNewPassword, setShowNewPassword] = useState(false)
	const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false)

	const onSubmit = data => {
		changePasswordMutation.mutate(data)
		reset()
	}
	return (
		<div className='PrflBox PrflBox2'>
			<div className='PrflBoxHdd text-center HdBrdBttm'>
				<h4>{t('Change Password')}</h4>
			</div>
			<div className='PrflBoxDtlMain ChngPassInpt'>
				<form className='LogForm cus-form ' onSubmit={handleSubmit(onSubmit)}>
					<div className='row'>
						<div className='col-lg-4'>
							<div className='mb-3'>
								<label htmlFor='e1' className='form-label '>
									{t('Old Password')}
								</label>
								<div className=' nji_oki mb-3 InptGrp'>
									<input
										type={showCurrentPassword ? 'text' : 'password'}
										className='form-control'
										id='e1'
										placeholder={t('Enter Your Old Password')}
										{...register('password')}
									/>
									<div className=' ml-0'>
										<span
											className='input-group-text'
											id='basic-addon2'
											onClick={() =>
												setShowCurrentPassword(!showCurrentPassword)
											}
										>
											{!showCurrentPassword ? (
												<svg
													width='32'
													height='32'
													viewBox='0 0 32 32'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M13 16C13 16.7956 13.3161 17.5587 13.8787 18.1213C14.4413 18.6839 15.2044 19 16 19C16.7956 19 17.5587 18.6839 18.1213 18.1213C18.6839 17.5587 19 16.7956 19 16C19 15.2044 18.6839 14.4413 18.1213 13.8787C17.5587 13.3161 16.7956 13 16 13C15.2044 13 14.4413 13.3161 13.8787 13.8787C13.3161 14.4413 13 15.2044 13 16Z'
														stroke='#2BF3FF'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
													<path
														d='M29 16C25.5333 22 21.2 25 16 25C10.8 25 6.46667 22 3 16C6.46667 10 10.8 7 16 7C21.2 7 25.5333 10 29 16Z'
														stroke='#2BF3FF'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
													<path d='M30 3L3 30' stroke='#2BF3FF' />
												</svg>
											) : (
												<svg
													width='32'
													height='32'
													viewBox='0 0 32 32'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M13 16C13 16.7956 13.3161 17.5587 13.8787 18.1213C14.4413 18.6839 15.2044 19 16 19C16.7956 19 17.5587 18.6839 18.1213 18.1213C18.6839 17.5587 19 16.7956 19 16C19 15.2044 18.6839 14.4413 18.1213 13.8787C17.5587 13.3161 16.7956 13 16 13C15.2044 13 14.4413 13.3161 13.8787 13.8787C13.3161 14.4413 13 15.2044 13 16Z'
														stroke='#2BF3FF'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
													<path
														d='M29 16C25.5333 22 21.2 25 16 25C10.8 25 6.46667 22 3 16C6.46667 10 10.8 7 16 7C21.2 7 25.5333 10 29 16Z'
														stroke='#2BF3FF'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											)}
										</span>
									</div>
								</div>
							</div>
							{errors.password && (
								<p className='errorMsg'>{t(errors.password.message)}</p>
							)}
						</div>
						<div className='col-lg-4'>
							<div className='mb-3'>
								<label htmlFor='e1' className='form-label '>
									{t('New Password')}
								</label>
								<div className=' nji_oki mb-3 InptGrp'>
									<input
										type={showNewPassword ? 'text' : 'password'}
										className='form-control'
										id='e1'
										placeholder={t('Enter Your New Password')}
										{...register('newpassword')}
									/>
									<div className=' ml-0'>
										<span
											className='input-group-text'
											id='basic-addon2'
											onClick={() => setShowNewPassword(!showNewPassword)}
										>
											{!showNewPassword ? (
												<svg
													width='32'
													height='32'
													viewBox='0 0 32 32'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M13 16C13 16.7956 13.3161 17.5587 13.8787 18.1213C14.4413 18.6839 15.2044 19 16 19C16.7956 19 17.5587 18.6839 18.1213 18.1213C18.6839 17.5587 19 16.7956 19 16C19 15.2044 18.6839 14.4413 18.1213 13.8787C17.5587 13.3161 16.7956 13 16 13C15.2044 13 14.4413 13.3161 13.8787 13.8787C13.3161 14.4413 13 15.2044 13 16Z'
														stroke='#2BF3FF'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
													<path
														d='M29 16C25.5333 22 21.2 25 16 25C10.8 25 6.46667 22 3 16C6.46667 10 10.8 7 16 7C21.2 7 25.5333 10 29 16Z'
														stroke='#2BF3FF'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
													<path d='M30 3L3 30' stroke='#2BF3FF' />
												</svg>
											) : (
												<svg
													width='32'
													height='32'
													viewBox='0 0 32 32'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M13 16C13 16.7956 13.3161 17.5587 13.8787 18.1213C14.4413 18.6839 15.2044 19 16 19C16.7956 19 17.5587 18.6839 18.1213 18.1213C18.6839 17.5587 19 16.7956 19 16C19 15.2044 18.6839 14.4413 18.1213 13.8787C17.5587 13.3161 16.7956 13 16 13C15.2044 13 14.4413 13.3161 13.8787 13.8787C13.3161 14.4413 13 15.2044 13 16Z'
														stroke='#2BF3FF'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
													<path
														d='M29 16C25.5333 22 21.2 25 16 25C10.8 25 6.46667 22 3 16C6.46667 10 10.8 7 16 7C21.2 7 25.5333 10 29 16Z'
														stroke='#2BF3FF'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											)}
										</span>
									</div>
								</div>
							</div>
							{errors.newpassword && (
								<p className='errorMsg'>{t(errors.newpassword.message)}</p>
							)}
						</div>
						<div className='col-lg-4'>
							<div className='mb-3'>
								<label htmlFor='e1' className='form-label '>
									{t('Confirm Password')}
								</label>
								<div className=' nji_oki mb-3 InptGrp'>
									<input
										type={showConfirmNewPassword ? 'text' : 'password'}
										className='form-control'
										id='e1'
										placeholder={t('Enter Your Confirm Password')}
										{...register('confirmpassword')}
									/>
									<div className=' ml-0'>
										<span
											className='input-group-text'
											id='basic-addon2'
											onClick={() =>
												setShowConfirmNewPassword(!showConfirmNewPassword)
											}
										>
											{!showConfirmNewPassword ? (
												<svg
													width='32'
													height='32'
													viewBox='0 0 32 32'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M13 16C13 16.7956 13.3161 17.5587 13.8787 18.1213C14.4413 18.6839 15.2044 19 16 19C16.7956 19 17.5587 18.6839 18.1213 18.1213C18.6839 17.5587 19 16.7956 19 16C19 15.2044 18.6839 14.4413 18.1213 13.8787C17.5587 13.3161 16.7956 13 16 13C15.2044 13 14.4413 13.3161 13.8787 13.8787C13.3161 14.4413 13 15.2044 13 16Z'
														stroke='#2BF3FF'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
													<path
														d='M29 16C25.5333 22 21.2 25 16 25C10.8 25 6.46667 22 3 16C6.46667 10 10.8 7 16 7C21.2 7 25.5333 10 29 16Z'
														stroke='#2BF3FF'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
													<path d='M30 3L3 30' stroke='#2BF3FF' />
												</svg>
											) : (
												<svg
													width='32'
													height='32'
													viewBox='0 0 32 32'
													fill='none'
													xmlns='http://www.w3.org/2000/svg'
												>
													<path
														d='M13 16C13 16.7956 13.3161 17.5587 13.8787 18.1213C14.4413 18.6839 15.2044 19 16 19C16.7956 19 17.5587 18.6839 18.1213 18.1213C18.6839 17.5587 19 16.7956 19 16C19 15.2044 18.6839 14.4413 18.1213 13.8787C17.5587 13.3161 16.7956 13 16 13C15.2044 13 14.4413 13.3161 13.8787 13.8787C13.3161 14.4413 13 15.2044 13 16Z'
														stroke='#2BF3FF'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
													<path
														d='M29 16C25.5333 22 21.2 25 16 25C10.8 25 6.46667 22 3 16C6.46667 10 10.8 7 16 7C21.2 7 25.5333 10 29 16Z'
														stroke='#2BF3FF'
														strokeWidth='1.5'
														strokeLinecap='round'
														strokeLinejoin='round'
													/>
												</svg>
											)}
										</span>
									</div>
								</div>
							</div>
							{errors.confirmpassword && (
								<p className='errorMsg'>{t(errors.confirmpassword.message)}</p>
							)}
						</div>
					</div>
					<div className='form-cntwalt'>
						<button className='btn btn-primary' type='submit'>
							{t('Submit')}
						</button>
					</div>
				</form>
			</div>
		</div>
	)
}
