import React, { Fragment, useState, useEffect } from 'react'
import { Modal, ModalBody } from 'reactstrap'
import Header from '../../common/Header/Header'
import Footer from '../../common/Footer/Footer'
import { useProfile, useChangeProfile, useUpdateImage } from './api'
import userImg from '../../assets/images/usr-img.png'
import load_gif from '../../assets/images/loading_gif.gif'
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import { useForm } from 'react-hook-form'
import { ChangePassword } from './ChangePassword'
import { useTranslation } from 'react-i18next'

const USERNAME_REGEX = /^[a-zA-Z0-9\s]{3,20}$/

const Myprofile = () => {
	const { t } = useTranslation()

	const [modal, setModal] = useState(false)
	const toggle = () => setModal(!modal)

	const formSchema = Yup.object().shape({
		userName: Yup.string()
			.required('Enter User Name.')
			.min(3, 'User Name should be atleast 3 characters.')
			.max(20, 'User Name should be within 20 characters.')
			.matches(USERNAME_REGEX, 'Enter only Alphanumeric characters.')
	})

	const formOptions = { resolver: yupResolver(formSchema) }

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm(formOptions)

	const profileMutation = useProfile()
	const changeProfileMutation = useChangeProfile()
	const updateImageMutation = useUpdateImage()

	const updateImage =
		updateImageMutation && updateImageMutation?.data?.data?.data

	useEffect(() => {
		profileMutation.mutate()
	}, [changeProfileMutation.isSuccess])

	useEffect(() => {
		reset(profileMutation.data?.data?.data)
	}, [profileMutation.isSuccess])

	const onSubmit = ({ email, userName }) => {
		changeProfileMutation.mutate({
			email,
			userName,
			Image: updateImage
		})
		reset()
		toggle()
	}

	const [image, setImage] = useState(null)
	const [error, setError] = useState(null)
	const [disabled, setDisabled] = useState(false)

	const handleImageChange = async event => {
		const selectedImage = event.target.files[0]

		if (!selectedImage) return

		const allowedFormats = [
			'image/jpeg',
			'image/png',
			'image/gif',
			'image/svg+xml'
		]
		if (!allowedFormats.includes(selectedImage.type)) {
			setError(
				'Invalid file format. Please upload a JPG, PNG, GIF, or SVG file.'
			)
			setDisabled(true)
			return
		}

		if (selectedImage.size > 2 * 1024 * 1024) {
			setError('File size exceeds 2MB limit. Please upload a smaller file.')
			setDisabled(true)
			return
		}

		const imageObject = new Image()
		imageObject.src = URL.createObjectURL(selectedImage)
		imageObject.onload = () => {
			if (imageObject.width > 6000 || imageObject.height > 6000) {
				setError(
					'Image dimensions exceed the limit of 6000x6000. Please upload a smaller image.'
				)
				setDisabled(true)
				return
			}

			setError(null)
			setDisabled(false)

			setImage(selectedImage)

			const formData = new FormData()
			formData.append('image', selectedImage)

			try {
				updateImageMutation.mutate(formData)
			} catch (error) {
			}
		}
		imageObject.onerror = () => {
			setImage(null)
			setError('Unsupported image file')
			setDisabled(true)
		}
	}

	return (
		<Fragment>
			<Header />
			<div className='pagemain-content'>
				<div className='MyPrflMain'>
					<div className='container'>
						<div className='PrflBox mb-3'>
							<div className='PrflBoxHdd text-center HdBrdBttm'>
								<h4>{t('My Profile')}</h4>
							</div>
							<div className='PrflBoxDtlMain'>
								<div className='row align-items-center'>
									<div className='col-lg-5 mb-2 '>
										<div className='UsrDtlSec'>
											<div className='UsrImg UsrImg_ndji mr-5'>
												<img
													src={
														profileMutation.isLoading
															? load_gif
															: profileMutation
															? profileMutation?.data?.data?.data?.Image !== ''
																? profileMutation?.data?.data?.data?.Image
																: userImg
															: userImg
													}
													className='img-fluid'
													alt=''
												/>
											</div>
											<div className='UsrDtlCnt'>
												<p>{t('User Name')}</p>
												<h4>
													{profileMutation.isLoading ? (
														<>{t('Loading...')}</>
													) : profileMutation ? (
														profileMutation?.data?.data?.data?.userName
													) : (
														<>{t('Profile')}</>
													)}
												</h4>
											</div>
										</div>
									</div>
									<div className='col-lg-4 mb-2 col-md-6'>
										<div className='UsrDtlCnt'>
											<p>{t('Email')}</p>
											<h4>
												{profileMutation.isLoading ? (
													<>{t('Loading...')}</>
												) : profileMutation ? (
													profileMutation?.data?.data?.data?.email
												) : (
													<>{t('Profile')}</>
												)}
											</h4>
										</div>
									</div>
									<div className='col-lg-3 mb-2 col-md-6'>
										<div className='text-lg-right'>
											<button
												className='btn btn-primary Btn155-43'
												type='button'
												onClick={toggle}
											>
												{t('Edit')}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
						<ChangePassword />
					</div>
				</div>
			</div>
			<Footer />
			<Modal
				isOpen={modal}
				toggle={toggle}
				backdrop='static'
				className='cntwlt-modal cmnmodal EdtMdl modal-dialog-centered'
			>
				<ModalBody>
					<h5 className='modeltitle mb-4'>{t('My Profile')}</h5>
					<form className='LogForm cus-form ' onSubmit={handleSubmit(onSubmit)}>
						<div className='setingsupload'>
							<div className='variants'>
								<div className='file'>
									<label htmlFor='input-file'>
										{image ? (
											<img
												src={
													updateImageMutation.isLoading
														? load_gif
														: URL.createObjectURL(image)
														? URL.createObjectURL(image)
														: userImg
												}
												className='img-fluid'
												alt=''
											/>
										) : (
											<img
												src={
													profileMutation
														? profileMutation?.data?.data?.data?.Image !== ''
															? profileMutation?.data?.data?.data?.Image
															: userImg
														: userImg
												}
												className='img-fluid'
												alt=''
											/>
										)}

										<div className='editicg'>
											<svg
												xmlns='http://www.w3.org/2000/svg'
												viewBox='0 0 32 32'
												width='32px'
												height='32px'
											>
												<path d='M 23.90625 3.96875 C 22.859375 3.96875 21.8125 4.375 21 5.1875 L 5.1875 21 L 5.125 21.3125 L 4.03125 26.8125 L 3.71875 28.28125 L 5.1875 27.96875 L 10.6875 26.875 L 11 26.8125 L 26.8125 11 C 28.4375 9.375 28.4375 6.8125 26.8125 5.1875 C 26 4.375 24.953125 3.96875 23.90625 3.96875 Z M 23.90625 5.875 C 24.410156 5.875 24.917969 6.105469 25.40625 6.59375 C 26.378906 7.566406 26.378906 8.621094 25.40625 9.59375 L 24.6875 10.28125 L 21.71875 7.3125 L 22.40625 6.59375 C 22.894531 6.105469 23.402344 5.875 23.90625 5.875 Z M 20.3125 8.71875 L 23.28125 11.6875 L 11.1875 23.78125 C 10.53125 22.5 9.5 21.46875 8.21875 20.8125 Z M 6.9375 22.4375 C 8.136719 22.921875 9.078125 23.863281 9.5625 25.0625 L 6.28125 25.71875 Z' />
											</svg>
										</div>
									</label>
									<input
										id='input-file'
										type='file'
										onChange={handleImageChange}
										accept='image/gif, image/jpeg,image/jpg, image/png, image/svg'
									/>
								</div>
							</div>
						</div>
						{error && <p className='errorMsg'>{error}</p>}

						<div className='mb-4'>
							<label htmlFor='e1' className='form-label '>
								{t('User Name')}
							</label>
							<input
								type='text'
								className='form-control'
								id='e1'
								placeholder={t('User Name')}
								{...register('userName')}
							/>
						</div>
						{errors.userName && (
							<p className='errorMsg'>{errors.userName?.message}</p>
						)}
						<div className='text-center'>
							{updateImageMutation.isLoading ? (
								<button
									className='btn btn-primary Btn155-43 mr-2 mb-2 cursor-not-allowed'
									type='submit'
									disabled
								>
									{t('Update')}
								</button>
							) : (
								<button
									className='btn btn-primary Btn155-43 mr-2 mb-2'
									type='submit'
									disabled={disabled}
								>
									{t('Update')}
								</button>
							)}
							<button
								className='btn btn-secondary Btn155-43 mb-2'
								type='button'
								onClick={toggle}
							>
								{t('Cancel')}
							</button>
						</div>
					</form>
				</ModalBody>
			</Modal>
		</Fragment>
	)
}

export default Myprofile
