import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Request } from '../../../utils/axios-utils'

const profile = async() => {
	return Request({ url: '/user/profile', method: 'post' })
}

export const useProfile = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	return useMutation(profile, {
		onSuccess: loginSuccessData => {
			if (loginSuccessData.data.status === 400) {
				toast.error(t(loginSuccessData.data.message))
			}

			if (loginSuccessData.data.status === 200) {
				toast.success(t('User password updated successfully'))
			}

			if (loginSuccessData.data.status === 413) {
				navigate('/404')
			}


			if (loginSuccessData.data.status === false) {
				toast.error(t(loginSuccessData.data.message))
			}
		}
	})
}

const changeProfile = async data => {
	return Request({ url: '/user/update-profile', method: 'post', data })
}

export const useChangeProfile = () => {
	const { t } = useTranslation()

	return useMutation(changeProfile, {
		onSuccess: SuccessData => {
			if (SuccessData?.data?.status === 402) {
				toast.error(t(SuccessData?.data?.message))
			} else if (SuccessData?.data?.status === 400) {
				toast.error(t(SuccessData?.data?.message))
			} else if (SuccessData?.data?.status === 200) {
				toast.success(t(SuccessData?.data?.message))
			}
		}
	})
}

const changePassword = async data => {
	return Request({ url: '/user/changepassword', method: 'post', data })
}

export const useChangePassword = () => {
	const { t } = useTranslation()

	return useMutation(changePassword, {
		onSuccess: SuccessData => {
			if (SuccessData?.data?.status === 402) {
				toast.error(t(SuccessData?.data?.message))
			} else if (SuccessData?.data?.status === 400) {
				toast.error(t(SuccessData?.data?.message))
			} else if (SuccessData?.data?.status === 200) {
				toast.success(t('User password updated successfully'))
			}
		}
	})
}


const updateImage = async data => {
	return Request({ url: '/user/upload_image', method: 'post', data })
}

export const useUpdateImage = () => {
	const { t } = useTranslation()

	return useMutation(updateImage, {
		onSuccess: SuccessData => {
			if (SuccessData?.data?.status === 402) {
				toast.error(t(SuccessData?.data?.message))
			} else if (SuccessData?.data?.status === 400) {
				toast.error(t(SuccessData?.data?.message))
			} else if (SuccessData?.data?.status === 200) {
			}
		}
	})
}
