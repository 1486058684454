import React, { useEffect } from 'react'
import './App.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../src/scss/style.scss'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { Toaster, toast } from 'react-hot-toast'
import { QueryClientProvider } from 'react-query'
import { queryClient } from './utils/react-query'
import { Provider } from 'react-redux'
import { store } from './store'
import Home from './views/Home/Home'
import Deposit from './views/Deposit/Deposit'
import Walletaddress from './views/Walletaddress/Walletaddress'
import Login from './views/Login/Login'
import Signup from './views/Signup/Signup'
import Thanks from './views/Thanks/Thanks'
import Forgotpassword from './views/Forgotpassword/Forgotpassword'
import ResetPassword from './views/Resetpassword/ResetPassword'
import Contact from './views/Contact/Contact'
import History from './views/History/History'
import About from './views/About/About'
import Pagenotfound from './views/Pagenotfound/Pagenotfound'
import Myprofile from './views/Myprofile/Myprofile'
import Verify from './views/Thanks/Verify'
import { axiosData } from './utils/axios-utils'
import { WagmiConfig } from 'wagmi'
import { config } from './web3/connectors/connectors'
import './language/i8nxt'
import { useTranslation } from 'react-i18next'
import UnderMaintenance from './views/UnderMaintainence'

const App = props => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	useEffect(() => {
		axiosData.interceptors.response.use(
			response => {
				if (response.data.status === 700) {
					toast.error(t('Session has timed out. Please login again'))
					localStorage.clear()
					return navigate('/login')
				} else {
					return response
				}
			},
			err => {
				if (err?.code === 'ERR_NETWORK') {
				} else if (
					err?.response.status === 401 &&
					err?.config &&
					!err?.config.__isRetryRequest
				) {
					toast.error(t('Unauthorized request'))
				}
			}
		)
	}, [])
	return (
		<React.Fragment>
			<Provider store={store}>
				<QueryClientProvider client={queryClient}>
					<WagmiConfig config={config}>
						<Toaster position='top-right' />
						<Routes history={props.history}>
							<Route path='/' element={<Home />} />
							<Route path='/verifyemail/:userId' element={<Login />} />
							<Route path='/deposit' element={<Deposit />} />
							<Route path='/deposit/:exchangeId' element={<Deposit />} />

							<Route
								path='/walletaddress/:create'
								element={<Walletaddress />}
							/>
							<Route path='/login' element={<Login />} />
							<Route path='/signup' element={<Signup />} />
							<Route path='/thanks' element={<Thanks />} />
							<Route path='/verifyemail' element={<Verify />} />
							<Route path='/forgotpassword' element={<Forgotpassword />} />
							<Route path='/resetpassword/:token' element={<ResetPassword />} />
							<Route path='/contact' element={<Contact />} />
							<Route path='/history' element={<History />} />
							<Route path='/myprofile' element={<Myprofile />} />
							<Route path='/about' element={<About />} />
							<Route path='*' element={<Pagenotfound />} />
							<Route path='/404' element={<Pagenotfound />} />
							<Route path='/undermaintainence' element={<UnderMaintenance />} />
						</Routes>
					</WagmiConfig>
				</QueryClientProvider>
			</Provider>
		</React.Fragment>
	)
}

export default App
