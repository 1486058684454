import React, { Fragment, useState, useEffect } from 'react'
import {
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Modal,
	ModalBody
} from 'reactstrap'
import Header from '../../common/Header/Header'
import Footer from '../../common/Footer/Footer'
import exchangeImg from '../../assets/images/exchange-icon.svg'
import { NumericFormat } from 'react-number-format'
import { useSelector } from 'react-redux'
import { useCurrencyList, useCreateAddress, usePairPrice } from '../Home/api'
import Loader from '../../utils/loader'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useUpdateAddress } from './api'
import { setCreateExchange } from './slice'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TokenLoader from '../../utils/TokenLoader'
import { useCmsList } from '../About/api'
import { useDebounce } from '../../utils/toFixed'

const Walletaddress = props => {
	const { t } = useTranslation()

	const [modal, setModal] = useState(false)
	const toggle = () => setModal(!modal)

	const [modal1, setModal1] = useState(false)
	const toggle1 = () => setModal1(!modal1)

	const dispatch = useDispatch()
	const navigate = useNavigate()
	const CmsData = useCmsList()
	const createAddressMutation = useCreateAddress()

	const { create } = useParams()

	let ar = create.split('&')

	let obj = ar.map(el => {
		let x = el.split('=')
		return {
			[x[0]]: x[1]
		}
	})

	const currencyListMutation = useCurrencyList()
	const updateAddressMutation = useUpdateAddress()
	const ToPairPriceMutation = usePairPrice()

	const [currencyList, setCurrencyList] = useState([])
	const [senderAddress, setSenderAddress] = useState('')
	const [address, setAddress] = useState('')

	const [errorSenderAddress, setErrorSenderAddress] = useState('')
	const [errorAddress, setErrorAddress] = useState('')
	const [checkboxValue, setCheckboxValue] = useState(false)

	const [fromToken, setFromToken] = useState()
	const [toToken, setToToken] = useState()

	const [fromAmount, setFromAmount] = useState()
	const [toAmount, setToAmount] = useState()
	const [error, setError] = useState('')

	const [isDisabled, setIsDisabled] = useState(false)

	const token = useSelector(state => state.walletExchange.fromWalletToken)
	const initialExchange = useSelector(
		state => state.walletExchange.initialExchange
	)

	useEffect(() => {
		currencyListMutation.mutate()
	}, [])

	const listSocialLink = () => {
		if (currencyListMutation.isSuccess) {
			setCurrencyList(
				currencyListMutation && currencyListMutation?.data?.data
			)

			let dataObj = currencyListMutation?.data?.data?.filter(ob => {
				if (ob.currency === obj[0].from) {
					return ob
				}
			})

			let todataObj = currencyListMutation?.data?.data?.filter(ob => {
				if (ob.currency === obj[1].to) {
					return ob
				}
			})

			setFromToken(dataObj && dataObj[0])
			setToToken(todataObj && todataObj[0])
			setFromAmount(obj[2].amount)
			setToAmount(obj[2].estimate)
		} else {
			setCurrencyList([])
		}
	}

	useEffect(() => {
		listSocialLink()
	}, [currencyListMutation.isSuccess])

	const selectFromToken = data => {
		let temp = data
		if (temp._id === toToken._id) {
			setFromToken(toToken)
			setToToken(fromToken)
		} else {
			setFromToken(data)
		}
		amountCalculate()
	}

	const selectToToken = data => {
		let temp = data
		if (temp._id === fromToken._id) {
			setFromToken(toToken)
			setToToken(fromToken)
		} else {
			setToToken(data)
		}
		amountCalculate()
	}

	const swapChange = e => {
		e.preventDefault()
		setFromToken(toToken)
		setToToken(fromToken)
	}

	const onUserInput = values => {
		if (values === '') {
			setFromAmount(null)
			setToAmount(0)
			return
		}
		const regex = /^\d{1,15}(\.\d{1,15})?$/
		if (regex.test(+values)) {
			if (+values < ToPairPriceMutation?.data?.data?.fromMin) {
				setIsDisabled(true)
				setError(`Min amount ${ToPairPriceMutation?.data?.data?.fromMin}`)
				return
			} else if (+values > ToPairPriceMutation?.data?.data?.fromMax) {
				setIsDisabled(true)
				setError(`Max amount ${ToPairPriceMutation?.data?.data?.fromMax}`)
				return
			} else {
				setFromAmount(+values)
				setError('')
				setIsDisabled(false)
			}
		} else {
			setError('Pair is not supported')
		}
	}

	const debouncedSearchTerm = useDebounce(fromAmount, 100)

	const amountCalculate = async () => {
		const data = {
			fromCurrency: `${fromToken?.coinName}(${fromToken?.coinType})`,
			toCurrency: `${toToken?.coinName}(${toToken?.coinType})`,
			fromCurrencyPrice: +fromAmount
		}
		if (fromToken !== undefined && toToken !== undefined) {
			await ToPairPriceMutation.mutate(data)
		}
	}

	useEffect(() => {
		amountCalculate()
	}, [debouncedSearchTerm, fromToken, toToken])

	useEffect(() => {
		if (ToPairPriceMutation.isSuccess) {
			setToAmount(ToPairPriceMutation?.data?.data?.toCurrencyPrice)
		}
	}, [ToPairPriceMutation.isSuccess])

	const [acceptError, setAcceptError] = useState('')

	const createExchange = async () => {
		const data = {
			fromCurrency: fromToken.currency
				? fromToken.currency
				: initialExchange.fromCurrency,
			fromAmount: fromAmount ? fromAmount : initialExchange.fromAmount,
			toCurrency: toToken.currency
				? toToken.currency
				: initialExchange.toCurrency,
			toNetwork: toToken.Network ? toToken.Network : initialExchange.toNetwork,
			toAmount: toAmount
				? +Number(toAmount).toFixed(6)
				: initialExchange.toAmount
		}

		await createAddressMutation.mutate(data)
	}

	const exchangeData = async () => {
		if (senderAddress.length === 0 && address.length === 0) {
			setErrorSenderAddress('Enter address')
			setErrorAddress('Enter address')
		} else if (senderAddress.length === 0) {
			setErrorSenderAddress('Enter address')
		} else if (address.length === 0) {
			setErrorAddress('Enter address')
		} else {
			setErrorAddress('')
			setErrorSenderAddress('')
			if (checkboxValue) {
				window.scrollTo({
					top: 0,
					behavior: 'smooth'
				})
				setAcceptError('')
				toggle1()
			} else {
				setAcceptError('Please accept the disclaimer')
			}
		}
	}

	useEffect(() => {
		;(async () => {
			if (createAddressMutation.isSuccess) {
				dispatch(setCreateExchange(createAddressMutation?.data))
				const data = {
					exchangeId: createAddressMutation?.data?.data?.exchangeId,
					toAddress: address,
					userAddress: senderAddress,
					fromNetwork: fromToken.Network
						? fromToken.Network
						: initialExchange.fromNetwork,
					toNetwork: toToken.Network
						? toToken.Network
						: initialExchange.toNetwork,
					_id: localStorage.getItem('userid'),
					user_tracking_id: localStorage.getItem('user_tracking_id')
				}
				await updateAddressMutation.mutate(data)
			}
		})()
	}, [createAddressMutation.isSuccess])

	useEffect(() => {
		if (updateAddressMutation.isSuccess) {
			navigate(
				`/deposit/${createAddressMutation?.data?.data?.exchangeId}`
			)
		}
	}, [updateAddressMutation.isSuccess])

	const handleSenderAddressChange = event => {
		const newAddress = event.target.value

		const regex = /^0x[a-fA-F0-9]{40}$/
		const regexTrx = /^T[1-9A-HJ-NP-Za-km-z]{33}$/
		if (fromToken.Network === 'TRX') {
			if (newAddress !== '' || undefined) {
				if (regexTrx.test(newAddress)) {
					setSenderAddress(newAddress)
					setIsDisabled(false)
					setErrorSenderAddress('')
				} else {
					setSenderAddress(newAddress)
					setIsDisabled(true)
					setErrorSenderAddress('Please enter TRX wallet address')
				}
			} else {
				setIsDisabled(true)
				setErrorSenderAddress('Enter address')
			}
		} else if (fromToken.Network === 'ETH' || 'Polygon') {
			if (newAddress !== '' || undefined) {
				if (regex.test(newAddress)) {
					setSenderAddress(newAddress)
					setErrorSenderAddress('')
					setIsDisabled(false)
				} else {
					setSenderAddress(newAddress)
					setIsDisabled(true)
					setErrorSenderAddress('Please enter wallet address')
				}
			} else {
				setIsDisabled(true)
				setErrorSenderAddress('Enter address')
			}
		} else {
			setSenderAddress(newAddress)
			setIsDisabled(true)
			setErrorSenderAddress('Invalid address')
		}
	}

	const handleAddressChange = event => {
		const newAddress = event.target.value

		const regex = /^0x[a-fA-F0-9]{40}$/
		const regexTrx = /^T[1-9A-HJ-NP-Za-km-z]{33}$/
		if (toToken.Network === 'TRX') {
			if (newAddress !== '' || undefined) {
				if (regexTrx.test(newAddress)) {
					setAddress(newAddress)
					setIsDisabled(false)
					setErrorAddress('')
				} else {
					setAddress(newAddress)
					setIsDisabled(true)
					setErrorAddress('Please enter TRX wallet address')
				}
			} else {
				setIsDisabled(true)
				setErrorAddress('Enter address')
			}
		} else if (toToken.Network === 'ETH' || 'Polygon') {
			if (newAddress !== '' || undefined) {
				if (regex.test(newAddress)) {
					setAddress(newAddress)
					setErrorAddress('')
					setIsDisabled(false)
				} else {
					setAddress(newAddress)
					setIsDisabled(true)
					setErrorAddress('Please enter wallet address')
				}
			} else {
				setIsDisabled(true)
				setErrorAddress('Enter address')
			}
		} else {
			setAddress(newAddress)
			setIsDisabled(true)
			setErrorAddress('Invalid address')
		}
	}

	const handleCheckboxChange = event => {
		setCheckboxValue(event.target.checked)
		setAcceptError('')
	}

	const handleAcceptChange = event => {
		setAcceptError('')
		setCheckboxValue(true)
		toggle()
	}

	return (
		<Fragment>
			<Header />
			<div className='pagemain-content'>
				<section className='bridge-sec main_jiu'>
					<div className='container'>
						<div className='bridge-head text-center mb-4'>
							<h5>
								{t('Instant')} {t('Crypto Exchange')}
							</h5>
							<p>{t('Easy to Use & Free from Sign-up')}</p>
						</div>
						<div className='bridge-maincnt'>
							<div className='bridge-body'>
								<form action='' className='cus-form'>
									<div className='form-group'>
										<label htmlFor=''>{t('You Send')}</label>
										<div className='row justify-content-center'>
											<div className='col-sm-7 pr-sm-0'>
												<div className='cusform-input'>
													<NumericFormat
														onValueChange={({ value }) => onUserInput(value)}
														className='numeric-input'
														allowNegative={false}
														allowLeadingZeros
														value={fromAmount}
														onBlur={() => {
															if (fromAmount === '') {
																setError('Amount is required')
															}
														}}
													/>
													{error && (
														<div className='text-danger'>{t(error)}</div>
													)}
												</div>
											</div>
											<div className='col-sm-5 ColAut'>
												<div className='cusform-input'>
													<UncontrolledDropdown className='CustDrpDwn'>
														<DropdownToggle>
															<div className='inputcoin-btn'>
																<div className='coinbtn-main'>
																	<div className='d-flex justify-content-between align-items-center'>
																		{currencyListMutation.isLoading ? (
																			<TokenLoader />
																		) : (
																			<>
																				<div>
																					<img
																						src={
																							fromToken
																								? fromToken.coinImage
																								: token &&
																								  token.fromToken.coinImage
																						}
																						alt=''
																						className=''
																						style={{
																							width: '16px',
																							height: '16px'
																						}}
																					/>
																				</div>
																				<div className='mx-2 d-flex align-items-center'>
																					<h5 className='mr-2'>
																						{fromToken
																							? fromToken.coinName
																							: token &&
																							  token.fromToken.coinName}
																					</h5>
																					<span className='badge badge-pill badge-info'>
																						{fromToken
																							? fromToken.coinType
																							: token &&
																							  token.fromToken.coinType}
																					</span>
																				</div>
																				<div>
																					<svg
																						width='9'
																						height='6'
																						viewBox='0 0 9 6'
																						fill='none'
																						xmlns='http://www.w3.org/2000/svg'
																					>
																						<path
																							d='M1 1L4.5 5L8 1'
																							stroke='white'
																						/>
																					</svg>
																				</div>
																			</>
																		)}
																	</div>
																</div>
															</div>
														</DropdownToggle>
														<DropdownMenu>
															{currencyList.map(data => {
																return (
																	<DropdownItem
																		key={data._id}
																		onClick={() => selectFromToken(data)}
																	>
																		<img
																			src={data.coinImage}
																			alt=''
																			className='img-fluid DrpDwnImg mr-2'
																		/>
																		{data.coinName}{' '}
																		<span className='badge badge-pill badge-info'>
																			{data.coinType}
																		</span>
																	</DropdownItem>
																)
															})}
														</DropdownMenu>
													</UncontrolledDropdown>
												</div>
											</div>
										</div>
										<div className='ExcngeBtn'>
											<button
												className='btn btn-link'
												onClick={e => swapChange(e)}
											>
												<img src={exchangeImg} alt='#' />
											</button>
										</div>
									</div>

									<div className='form-group'>
										<label htmlFor=''>{t('You Get')}</label>
										<div className='row justify-content-center'>
											<div className='col-sm-7 pr-sm-0'>
												<div className='cusform-input'>
													<div className='valChange'>
														{error === 'Pair is not supported' ? (
															'-'
														) : currencyListMutation.isLoading ? (
															<div className='amountloader'></div>
														) : isNaN(toAmount) ? (
															'0.1'
														) : (
															parseFloat(toAmount.toFixed(10))
														)}
													</div>
												</div>
											</div>
											<div className='col-sm-5 ColAut'>
												<div className='cusform-input'>
													<UncontrolledDropdown className='CustDrpDwn'>
														<DropdownToggle>
															<div className='inputcoin-btn'>
																<div className='coinbtn-main'>
																	<div className='d-flex justify-content-between align-items-center'>
																		{currencyListMutation.isLoading ? (
																			<TokenLoader />
																		) : (
																			<>
																				<div>
																					<img
																						src={
																							toToken
																								? toToken.coinImage
																								: token &&
																								  token.toToken.coinImage
																						}
																						alt=''
																						className=''
																						style={{
																							width: '16px',
																							height: '16px'
																						}}
																					/>
																				</div>
																				<div className='mx-2 d-flex align-items-center'>
																					<h5 className='mr-2'>
																						{toToken
																							? toToken.coinName
																							: token && token.toToken.coinName}
																					</h5>
																					<span className='badge badge-pill badge-info'>
																						{toToken
																							? toToken.coinType
																							: token && token.toToken.coinType}
																					</span>
																				</div>
																				<div>
																					<svg
																						width='9'
																						height='6'
																						viewBox='0 0 9 6'
																						fill='none'
																						xmlns='http://www.w3.org/2000/svg'
																					>
																						<path
																							d='M1 1L4.5 5L8 1'
																							stroke='white'
																						/>
																					</svg>
																				</div>
																			</>
																		)}
																	</div>
																</div>
															</div>
														</DropdownToggle>
														<DropdownMenu>
															{currencyList.map(data => {
																return (
																	<DropdownItem
																		onClick={() => selectToToken(data)}
																	>
																		<img
																			src={data.coinImage}
																			alt=''
																			className='img-fluid DrpDwnImg mr-2'
																		/>
																		{data.coinName}{' '}
																		<span className='badge badge-pill badge-info'>
																			{data.coinType}
																		</span>
																	</DropdownItem>
																)
															})}
														</DropdownMenu>
													</UncontrolledDropdown>
												</div>
											</div>
										</div>
									</div>
									<div className='form-group'>
										<label htmlFor=''>{t('Enter the Sender Address')}</label>
										<div className='cusform-input AddrssInpt'>
											<input
												type='text'
												name=''
												id=''
												onChange={handleSenderAddressChange}
												placeholder={
													fromToken?.Network === 'TRX'
														? t('The recipient’s sender TRX wallet address')
														: t('The recipient’s sender wallet address')
												}
											/>
											{errorSenderAddress && (
												<div className='text-danger'>
													{t(errorSenderAddress)}
												</div>
											)}
										</div>
									</div>
									<div className='form-group'>
										<label htmlFor=''>{t('Enter the Receiver Address')}</label>
										<div className='cusform-input AddrssInpt'>
											<input
												type='text'
												name=''
												id=''
												onChange={handleAddressChange}
												placeholder={
													toToken?.Network === 'TRX'
														? t('The recipient’s receiver TRX wallet address')
														: t('The recipient’s receiver wallet address')
												}
											/>
											{errorAddress && (
												<div className='text-danger'>{t(errorAddress)}</div>
											)}
										</div>
									</div>
									<div className='CusChkBox text-center'>
										<div className='form-group mb-0'>
											<input
												type='checkbox'
												id='cus-check'
												onChange={handleCheckboxChange}
												checked={checkboxValue}
											/>
											<label for='cus-check'>
												{t('I have read')}{' '}
												<button
													type='button'
													className='btn btn-link py-0 px-1'
													onClick={toggle}
												>
													{t('Disclaimer')}
												</button>
											</label>
										</div>
										{acceptError && (
											<div className='text-danger'>{t(acceptError)}</div>
										)}
									</div>
									<div className='form-cntwalt BtnDisabld'>
										<button
											className={
												isDisabled
													? 'btn btn-primary disable'
													: 'btn btn-primary'
											}
											type='button'
											onClick={() => exchangeData()}
											disabled={isDisabled}
										>
											{createAddressMutation.isLoading ? (
												<Loader />
											) : updateAddressMutation.isLoading ? (
												<Loader />
											) : (
												<>{t('Create An Exchange')}</>
											)}
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
			</div>
			<Footer />

			<Modal
				isOpen={modal}
				toggle={toggle}
				backdrop='static'
				className='cmnmodal DsclmMdl modal-dialog-centered fWidth'
			>
				<ModalBody>
					<h5 className='modeltitle mb-3'>Disclaimer</h5>
					<div className='DsclmCntMain'>
						<div className='DsclmCntMainScrll'>
							<div className='DsclmHddCnt mb-3'>
								<h6>
									Please read this disclaimer carefully before using Neo Swap
									(the 'Service'). By accessing or using the Service, you agree
									to be bound by the terms and conditions stated in this
									disclaimer.
								</h6>
							</div>
							<div
								className='DsclmCnt'
								dangerouslySetInnerHTML={{
									__html: CmsData?.data?.data[1]?.content
								}}
							></div>
						</div>
					</div>
					<div className='cus-form '>
						<div className='form-cntwalt text-center'>
							{checkboxValue ? (
								<button
									className='btn btn-primary'
									type='button'
									onClick={() => toggle()}
								>
									Ok
								</button>
							) : (
								<button
									className='btn btn-primary'
									type='button'
									onClick={handleAcceptChange}
								>
									Accept
								</button>
							)}
						</div>
					</div>
				</ModalBody>
			</Modal>

			<Modal
				isOpen={modal1}
				toggle={toggle1}
				backdrop='static'
				className='cmnmodal DsclmMdl modal-dialog-centered fWidthApprove'
			>
				<ModalBody>
					<div style={{ position: 'relative' }}>
						<h5 className='modeltitle mb-3'>Important Announcement</h5>
						<button className='btn fWidthClose' onClick={toggle1}>
							X
						</button>
					</div>
					<div className='DsclmCntMain'>
						<div className='DsclmCntMainScrll'>
							<div className='DsclmHddCntApprove mb-3'>
								<h6>
									Kindly ensure that the amount you send from your wallet
									perfectly matches the amount specified in this order.
								</h6>
							</div>
						</div>
					</div>
					<div className='cus-form '>
						<div className='form-cntwalt text-center'>
							<button
								className='btn btn-primary'
								type='button'
								onClick={() => createExchange()}
							>
								{createAddressMutation.isLoading ? (
									<Loader />
								) : updateAddressMutation.isLoading ? (
									<Loader />
								) : (
									<>{t('Confirm')}</>
								)}
							</button>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</Fragment>
	)
}

export default Walletaddress
