import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { axiosData } from '../../../utils/axios-utils'
import { useTranslation } from 'react-i18next'
import DataService from '../../../utils/DataService'
const { postRequest } = DataService()

const resetPassword = async loginData => {
	return postRequest(`/user/resetpassword/${loginData?._id}`, {
		password: loginData.password,
		confirmpassword: loginData.confirmpassword
	})
}

export const useResetPassword = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	return useMutation(resetPassword, {
		onSuccess: loginSuccessData => {
			
			if (loginSuccessData?.status === 400) {
					toast.error(t(loginSuccessData?.message))
			}

			if (loginSuccessData?.status === 402) {
				toast.error(t(loginSuccessData?.message))
		}

			if (loginSuccessData?.status === 200) {
				toast.success(t(loginSuccessData?.message))
				return navigate('/login')
			}

			if (loginSuccessData?.status === false) {
					toast.error(t(loginSuccessData?.message))
			}
		}
	})
}
