export const generateMathCaptcha = () => {
    const operations = ['+', '-', 'x', '÷'];
    const operation = operations[Math.floor(Math.random() * operations.length)];
    let num1, num2, answer;
  
    switch (operation) {
      case '+':
        num1 = Math.floor(Math.random() * 10);
        num2 = Math.floor(Math.random() * 10);
        answer = num1 + num2;
        break;
      case '-':
        num1 = Math.floor(Math.random() * 20);
        num2 = Math.floor(Math.random() * 10);
        num1 = Math.max(num1, num2);
        answer = num1 - num2;
        break;
      case 'x':
        num1 = Math.floor(Math.random() * 10);
        num2 = Math.floor(Math.random() * 10);
        answer = num1 * num2;
        break;
      case '÷':
        answer = Math.floor(Math.random() * 10) + 1;
        num2 = Math.floor(Math.random() * 10) + 1;
        num1 = answer * num2;
        break;
      default:
        break;
    }
  
    return { num1, num2, operation, answer };
  };
  