import { configureStore } from '@reduxjs/toolkit'
import exchangeWalletReducer from '../views/Walletaddress/slice'

export const store = configureStore({
	reducer: {
		walletExchange: exchangeWalletReducer

	},
  });

