import React from 'react'

const TokenLoader = () => {
	return (
		<div className='spinner center'>
			<div className='spinner-blade'></div>
			<div className='spinner-blade'></div>
			<div className='spinner-blade'></div>
			<div className='spinner-blade'></div>
			<div className='spinner-blade'></div>
			<div className='spinner-blade'></div>
			<div className='spinner-blade'></div>
			<div className='spinner-blade'></div>
			<div className='spinner-blade'></div>
			<div className='spinner-blade'></div>
			<div className='spinner-blade'></div>
			<div className='spinner-blade'></div>
		</div>
	)
}

export default TokenLoader
