import { configureChains, createConfig } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'

import { CHAINS } from '../utils/chains'

export const { chains, publicClient } = configureChains(CHAINS, [
	publicProvider()
])

export const metaMaskConnector = new MetaMaskConnector({
	chains,
	options: {
		shimDisconnect: false
	}
})

export const config = createConfig({
	autoConnect: true,
	publicClient,
	connectors: [metaMaskConnector]
})
