import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { axiosData } from '../../../utils/axios-utils'
import { useTranslation } from 'react-i18next'
import DataService from '../../../utils/DataService'
const { postRequest } = DataService()

const supportPage = async data => {
	return postRequest('user/contactUs', data)
}

export const useSupportPage = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()

	return useMutation(supportPage, {
		onSuccess: loginSuccessData => {
			if (loginSuccessData?.status === 400) {
					toast.error(t(loginSuccessData?.message))
			}

			if (loginSuccessData?.status === 413) {
				navigate('/404')
			}

			if (loginSuccessData?.status === 200) {
				navigate('/')
				toast.success(t('Your query has been sent to admin successfully'))
			}

			if (loginSuccessData?.status === false) {
					toast.error(t(loginSuccessData?.message))
			}
		}
	})
}
