import { toast } from 'react-hot-toast'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { axiosData } from '../../../utils/axios-utils'
import { useTranslation } from 'react-i18next'
import DataService from '../../../utils/DataService'
const { postRequest } = DataService()

const findExchange = async data => {
	return postRequest('/user/YEZBUmO2mVylsqnAuJxLLQeJLjy', data)
}

export const useFindExchange = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	return useMutation(findExchange, {
		onSuccess: loginSuccessData => {
			if (loginSuccessData?.status === 400) {
				toast.error(t(loginSuccessData?.message))
			}

			if (loginSuccessData?.status === 413) {
				navigate('/404')
			}


			if (loginSuccessData?.status === 200) {
			}

			if (loginSuccessData?.status === false) {
				toast.error(t(loginSuccessData?.message))
			}
		}
	})
}

const exchangeDetail = async data => {
	return postRequest('/user/userDepositDetails', data)
}

export const useExchangeDetail = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	return useMutation(exchangeDetail, {
		onSuccess: loginSuccessData => {
			if (loginSuccessData?.status === 400) {
				toast.error(t(loginSuccessData?.message))
			}

			if (loginSuccessData?.status === 200) {
				toast.success(t(loginSuccessData?.message))
			}

			if (loginSuccessData?.status === false) {
				toast.error(t(loginSuccessData?.message))
			}
		}
	})
}
