import React, { Fragment, useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Loader from '../../utils/loader'
import Sidemenu from '../../common/Sidemenu/Sidemenu'
import { useLoginUser, useVerifyUser } from './api'
import { generateMathCaptcha } from '../../utils/generateMathCaptcha'

import exchangeImg from '../../assets/images/exchange-icon.svg'

const Login = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const loginMutation = useLoginUser()
	const verifyMutation = useVerifyUser()

	const [captcha, setCaptcha] = useState(generateMathCaptcha())
	const [captchaValue, setCaptchaValue] = useState('')
	const [captchaError, setCaptchaError] = useState('')
	const [submitEnabled, setSubmitEnabled] = useState(false)

	const [showPassword, setShowPassword] = useState(false)

	let { userId } = useParams()

	useEffect(() => {
		if (userId) {
			verifyMutation.mutate(userId)
		}
	}, [])
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset
	} = useForm()

	const onSubmit = data => {
		if (parseInt(captchaValue) === captcha.answer) {
			loginMutation.mutate(data)
			reset()
		} else {
			setCaptchaError('Incorrect captcha!')
		}
	}

	const handleCaptchaChange = e => {
		const value = e.target.value
		if (!isNaN(value)) {
			setCaptchaValue(value)
			if (value !== '') {
				if (parseInt(value) === captcha.answer) {
					setSubmitEnabled(parseInt(value) === captcha.answer)
					setCaptchaError('')
				} else {
					setSubmitEnabled(false)
					setCaptchaError('Incorrect captcha!')
				}
			} else {
				setSubmitEnabled(false)
				setCaptchaError('')
			}
		}
	}

	const regenerateCaptcha = e => {
		e.preventDefault()
		setCaptcha(generateMathCaptcha())
		setCaptchaValue('')
		setSubmitEnabled(false)
	}

	useEffect(() => {
		if (submitEnabled) {
			setCaptchaError('')
		}
	}, [submitEnabled])

	useEffect(() => {
		const check = localStorage.getItem('authToken')
		if (check) {
			navigate(-1)
		}
	}, [])

	return (
		<Fragment>
			<div className='BgltNone'>
				<div className='page-tot'>
					<div className='LogMainSection'>
						<div className='row align-items-center mx-0'>
							<Sidemenu />
							<div className='col-lg-6'>
								<div className='LognSetMain py-3'>
									<div className='LognRtMain'>
										{verifyMutation?.data?.status === 200 ? (
											<div className='LognHdd text-center mb-5'>
												<h4>{t('Email verified')}</h4>
												<p>{t('Your email address has been confirmed')}.</p>
												<p>{t('Now you can log in')}.</p>
											</div>
										) : (
											<div className='LognHdd text-center mb-5'>
												<h4>{t('Log In')}</h4>
												<p>
													{t('Not customer yet')}?{' '}
													<Link to='/signup' className='LnkTxtHead'>
														{t('Sign Up')}
													</Link>
												</p>
											</div>
										)}

										<form className='LogForm' onSubmit={handleSubmit(onSubmit)}>
											<div className='mb-3'>
												<label htmlFor='e1' className='form-label sr-only'>
													{t('Email')}
												</label>
												<input
													type='email'
													className='form-control'
													id='e1'
													placeholder={t('Email Id')}
													{...register('email', {
														required: 'Email is required',
														pattern: {
															value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
															message: 'Email is not valid'
														}
													})}
													autoFocus
												/>
											</div>
											{errors.email && (
												<p className='errorMsg'>{t(errors.email.message)}</p>
											)}
											<div className='input-group mb-3 InptGrp'>
												<input
													type={showPassword ? 'text' : 'password'}
													className='form-control'
													placeholder={t('Password')}
													aria-label="Recipient's username"
													aria-describedby='basic-addon2'
													{...register('password', {
														required: true
													})}
												/>
												<div
													className='input-group-append ml-0'
													onClick={() => setShowPassword(!showPassword)}
												>
													<span className='input-group-text' id='basic-addon2'>
														{showPassword ? (
															<svg
																width='32'
																height='32'
																viewBox='0 0 32 32'
																fill='none'
																xmlns='http://www.w3.org/2000/svg'
															>
																<path
																	d='M13 16C13 16.7956 13.3161 17.5587 13.8787 18.1213C14.4413 18.6839 15.2044 19 16 19C16.7956 19 17.5587 18.6839 18.1213 18.1213C18.6839 17.5587 19 16.7956 19 16C19 15.2044 18.6839 14.4413 18.1213 13.8787C17.5587 13.3161 16.7956 13 16 13C15.2044 13 14.4413 13.3161 13.8787 13.8787C13.3161 14.4413 13 15.2044 13 16Z'
																	stroke='#2BF3FF'
																	strokeWidth='1.5'
																	strokeLinecap='round'
																	strokeLinejoin='round'
																/>
																<path
																	d='M29 16C25.5333 22 21.2 25 16 25C10.8 25 6.46667 22 3 16C6.46667 10 10.8 7 16 7C21.2 7 25.5333 10 29 16Z'
																	stroke='#2BF3FF'
																	strokeWidth='1.5'
																	strokeLinecap='round'
																	strokeLinejoin='round'
																/>
															</svg>
														) : (
															<svg
																width='32'
																height='32'
																viewBox='0 0 32 32'
																fill='none'
																xmlns='http://www.w3.org/2000/svg'
															>
																<path
																	d='M13 16C13 16.7956 13.3161 17.5587 13.8787 18.1213C14.4413 18.6839 15.2044 19 16 19C16.7956 19 17.5587 18.6839 18.1213 18.1213C18.6839 17.5587 19 16.7956 19 16C19 15.2044 18.6839 14.4413 18.1213 13.8787C17.5587 13.3161 16.7956 13 16 13C15.2044 13 14.4413 13.3161 13.8787 13.8787C13.3161 14.4413 13 15.2044 13 16Z'
																	stroke='#2BF3FF'
																	strokeWidth='1.5'
																	strokeLinecap='round'
																	strokeLinejoin='round'
																/>
																<path
																	d='M29 16C25.5333 22 21.2 25 16 25C10.8 25 6.46667 22 3 16C6.46667 10 10.8 7 16 7C21.2 7 25.5333 10 29 16Z'
																	stroke='#2BF3FF'
																	strokeWidth='1.5'
																	strokeLinecap='round'
																	strokeLinejoin='round'
																/>
																<path d='M30 3L3 30' stroke='#2BF3FF' />
															</svg>
														)}
													</span>
												</div>
											</div>
											{errors.password?.type === 'required' && (
												<p className='errorMsg'>{t('Password is required')}</p>
											)}

											<div className='captcha__input mb-3'>
												<label className='captcha__text'>
													{captcha.num1} {captcha.operation} {captcha.num2} =
												</label>
												<input
													type='text'
													className='captcha__control'
													value={captchaValue}
													onChange={handleCaptchaChange}
													style={{ width: '100px' }}
												/>
												<button
													className='btn btn-link'
													onClick={e => regenerateCaptcha(e)}
												>
													<img src={exchangeImg} alt='#' />
												</button>
											</div>
											{captchaError && (
												<p className='errorMsg'>{t(captchaError)}</p>
											)}

											<div className='text-right'>
												<Link to='/forgotpassword' className='LnkTxt'>
													{t('Forgot Password')} ?
												</Link>
											</div>
											<div className='text-center mt-4 pt-2'>
												<button
													className='btn btn-primary Btn215-43'
													type='submit'
													disabled={!submitEnabled}
												>
													{loginMutation.isLoading ? (
														<Loader />
													) : (
														<>{t('Login')}</>
													)}
												</button>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</Fragment>
	)
}

export default Login
